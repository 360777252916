import React, { useContext, useEffect, useState, useRef, useReducer } from "react";
import {
    StyleSheet,
    Text,
    TextInput,
    View,
    Dimensions,
    Animated,
    Image,
    TouchableOpacity,
    ScrollView,
    ActivityIndicator,
    Keyboard,
    Alert,
    Platform
} from "react-native";
import { PaystackConsumer } from 'react-paystack';
import AsyncStorage from '@react-native-community/async-storage';
import Modal from 'modal-react-native-web';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import * as Location from 'expo-location';
import HideWithKeyboard from 'react-native-hide-with-keyboard';
import firebase from "firebase";
import "firebase/firestore";
import Constants from 'expo-constants';
//import PaystackWebView from 'react-native-paystack-webview'; 
//import PaystackWebView from '../../utilities/PaystackWebView';
//import NewPaystack from '../../utilities/NewPaystack'; 
import RNPickerSelect from "react-native-picker-select";
import PWAPrompt from 'react-ios-pwa-prompt'
import AxiosCall from "../../utilities/Axios"
import checkCreditCard from '../../utilities/creditcard'
import cancelIcon from '../../../assets/cancel.png'
import { AREAS, LEKKI_AREAS, IKOYI_AREAS, VICTORIA_ISLAND_AREAS } from '../../utilities/AreasUtility'
// import HomeComponent from "../Components/HomeComponent";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const getCookies = function () {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }
    return cookies;
};

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

const areaMapping = {
    "Lekki": LEKKI_AREAS,
    "Ikoyi": IKOYI_AREAS,
    "Victoria Island": VICTORIA_ISLAND_AREAS
}
const deliveryDisclaimerMessage = "- OrderCash can only deliver within Lekki, Ikoyi & Victoria Island -"

const HomeScreen = ({ navigation }) => {
    const [indicator, setIndicator] = useState(false);
    // card details
    const [modalVisible, setModalVisible] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    const [day, setDay] = useState(0)
    const [month, setMonth] = useState(0)
    const [cvv, setCVV] = useState()
    // charge details
    const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
    const [charges, setCharges] = useState(1000);
    const [rates, setRates] = useState(0)
    const [orderAmt, setOrderAmt] = useState(0.0);
    const [realOrderAmt, setRealOrderAmt] = useState(0.0);
    const [total, setTotal] = useState(0.0);
    const [subtotal, setSubTotal] = useState(0.0);
    const [actualTotal, setActualTotal] = useState(0)
    const [paymentSuccessful, setPaymentSuccessful] = useState(true)
    const [error, setError] = useState('')
    // contact details
    const [user, setUser] = useState({})
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [area, setArea] = useState("")
    const [selectedArea, setSelectedArea] = useState(areaMapping)
    const [nearestAddress, setNearestAddress] = useState("")
    const [isAreaSelected, setIsAreaSelected] = useState(false)
    const [isNearestAddressSelected, setIsNearestAddressSelected] = useState(false)
    const [savedDataPresent, setSavedDataPresent] = useState(false)
    const [orderModalVisible, setOrderModalVisible] = useState(false)
    const [useNewAddress, setUseNewAddress] = useState(false)
    const [useNewNumber, setUseNewNumber] = useState(false)
    const [orderRequestTime, setOrderRequestTime] = useState("")
    const [orderVerifierCode, setOrderVerifierCode] = useState("")
    //////////////////////////////////////////////////////////////////// 
    const [message, showMessage] = useState("")
    const [nameError, setNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [signupModalVisible, setSignupModalVisible] = useState(false)
    const [pageNum, setPageNum] = useState("1")
    const [code, setCode] = useState('');
    const [location, setLocation] = useState('');
    const [isInValidArea, setIsInValidArea] = useState(false)
    const [showCancelButton, setShowCancelButton] = useState(true)
    const [confirmCodeActivity, setConfirmCodeActivity] = useState(false)
    const [verficationMethod, setVerificationMethod] = useState("SMS")
    const notificationListener = useRef();
    const responseListener = useRef();

    const generateReference = () => {
        let a = `${Math.round(Math.random() * 100000000)}`
        let b = new Date();
        let B = b.getTime();
        // console.log('reference geenrated is ', 'OC' + a + B)
        return 'OC' + a + B
    }
    const config = {
        /*  reference: (new Date()).getTime(), */
        reference: generateReference(),
        email: email || "",
        amount: actualTotal + '00',
        publicKey: 'pk_live_22d10555a0972142954700f20815afe8e695eae3',
        //publicKey:'pk_test_94ee9df047fb12b50589cd4840fbf948c1bfc4d7'
    };



    // you can call this function anything
    const handleSuccess = async (details) => {
        // Implementation for whatever you want to do with reference and after success call.
        console.log(details);
        //console.log('payment', e)
        // setPageNum("1"); 
        let secretCode = Math.floor(Math.random() * 899999 + 100000);
        // handle response here
        let data = {
            idToken: global.idToken,
            userID: global.uid,
            paymentDetails: details,
            name: name,
            phoneNumber: phoneNumber,
            email: email,
            address: nearestAddress,
            area: area,
            orderTotal: actualTotal,
            orderAmount: realOrderAmt,
            deliveryCharge: charges,
            rate: rates,
            paymentVendor: "Paystack",
            secretCode
        }
        await createOrderRequest(data);
        setOrderModalVisible(false)
        setModalVisible(false);
        setSignupModalVisible(false);
        await setPageNum("1")
        await setRates(0)
        await setTotal(0)
        await setOrderAmt(0)
        await setRealOrderAmt(0)
        await setActualTotal(0)
        await setSignupModalVisible(false);
        await setPaymentSuccessful(true);
        navigation.navigate("Orders")
    };

    // you can call this function anything
    const handleClose = async () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
        setModalVisible(false);
        setSignupModalVisible(false);
        setOrderModalVisible(false)
        setPageNum("1")
        setRates(0)
        setTotal(0)
        setOrderAmt(0)
        setRealOrderAmt(0)
        setActualTotal(0)
    }



    const componentProps = {
        ...config,
        text: 'Paystack Button Implementation',
        onSuccess: (reference) => handleSuccess(reference),
        onClose: handleClose
    };


    const signin = async (phoneNumber, mode) => {
        console.log('phoneNumber is ', phoneNumber)

        if (phoneNumber === '') {
            Alert.alert(res)
            return
        }
        if (phoneNumber == '+98765432100') {
            setPageNum("4");
            AsyncStorage.setItem("testAcct", "+98765432100")
            return
        }
        setConfirmCodeActivity(true)
        /*  <ActivityIndicator /> */
        try {
            const requestObj = {
                method: "POST",
                path: "signIn",
                data: { phoneNumber: phoneNumber, mode: mode },
            };

            const res = await AxiosCall(requestObj);
            if (res.msg === "OTP_SENT_NEW_USER_CREATED" || res.msg === "OTP_SENT_EXISTING_USER") {
                setPageNum('2')
            }
            else if (res.msg === 'PHONE_NUMBER_TOO_SHORT') {
                Alert.alert('Your Phone Number is too short. Please try again.')
            } else if (res.msg === 'PHONE_NUMBER_TOO_LONG') {
                Alert.alert('Your Phone Number is too long. Please try again.')
            } else {
                console.log("sign in message error", res);
            }
            setConfirmCodeActivity(false)
        } catch (err) {
            setConfirmCodeActivity(false)
            console.log("ERROR", err);
        }
    }

    const confirmCode = async () => {
        setConfirmCodeActivity(true)

        try {
            const requestObj = {
                method: "POST",
                path: "verifyOTP",
                data: { phoneNumber: phoneNumber, otpCode: code },
            }
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESSFUL_OTP_AUTHENTICATION") {
                await firebase.auth().signInWithCustomToken(res.token);
                const currentUser = firebase.auth().currentUser
                console.log('current useeer', currentUser);
                if (currentUser) {
                    const uid = currentUser.uid
                    global.uid = uid
                }
                setConfirmCodeActivity(false)
                setPageNum("3")
            } else if (res.msg === "INCORRECT_OTP_CODE") {
                Alert.alert("You entered an incorrect OTP_CODE, Try again")
                console.log(res.msg);
                setConfirmCodeActivity(false)
            }
            else if (res.msg === "INVALID_OTP_CODE") {
                Alert.alert("You entered an invalid OTP_CODE, Crosscheck and Try again")
                console.log(res.msg);
                setConfirmCodeActivity(false)
            } else {
                console.log("An Error ocurred in verifyOTP", res);
                setConfirmCodeActivity(false)
            }
        } catch (err) {
            await setConfirmCodeActivity(false)
            console.log("ERROR", err);
            Alert.alert('Sorry! something went wrong, please try again!')
        }
    }
    const getStoredData = async () => {
        // console.log('running getStoredData...')
        try {
            const userData = await AsyncStorage.getItem("userData");
            //const idToken = await AsyncStorage.getItem("idToken");
            const Data = await JSON.parse(userData);
            // console.log('parsed data in localstorage is ', userData, 'Data ', Data)
            if (Data !== null) {
                global.phoneNumber = Data.phoneNumber
                global.uid = Data.uid
                global.isAgent = Data.isAgent
                global.name = Data.name || ""
                global.phoneNumber = Data.phoneNumber
                setPhoneNumber(Data.phoneNumber)
                setName(Data.name || "")
                setEmail(Data.email)
                setNearestAddress(Data.locations[0].address)
                setArea(Data.locations[0].area)
                // setAddress(data.locations[data.locations.length - 1]["address"])
                // setSelectedArea(data.locations[data.locations.length - 1]["area"])
                setSavedDataPresent(true)
                // console.log('savedDataPresent(true)...')
            } else {
                console.log('no stored data... sorry')
            }
        } catch (error) {
            console.log("Error while fetching the data");
        }
    }

    const getUserDetails = async () => {
        getStoredData()
        try {
            console.log('uidddd', global.uid);
            if (global.uid) {
                const userRef = await firebase
                    .firestore()
                    .collection("Users")
                    .doc(global.uid)
                    .get();
                if (userRef.exists) {
                    const userDoc = userRef.data();
                    userDoc["uid"] = userRef.id
                    global.isAgent = userDoc.isAgent
                    setUser(userDoc)
                    AsyncStorage.setItem("userData", JSON.stringify(userDoc || {}))
                }
            }
        } catch (err) {
            console.error("ERROR OCCURED", err);
            Alert.alert("Something went wrong!!!", err.message);
        }
    }
    async function showNotification() {

        const result = await Notification.requestPermission();
        console.log('notification fired up...result is ', result)
        if (result === 'granted') {
            const noti = new Notification('Hello!', {
                body: 'It’s me.',
                icon: 'mario.png'
            });
            noti.onclick = () => alert('clicked');
        }
    }

    global.setMyPushTokenIfNeeded = async () => {
        if (!global.uid) await new Promise(resolve => { setTimeout(resolve, 2000); });
        if (!global.uid) await new Promise(resolve => { setTimeout(resolve, 6000); });
        if (!global.uid) await new Promise(resolve => { setTimeout(resolve, 20000); });
        if (!global.uid) await new Promise(resolve => { setTimeout(resolve, 80000); });

        try {

            if (Constants.isDevice) {
                const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
                let finalStatus = existingStatus;
                if (existingStatus !== 'granted') {
                    const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
                    finalStatus = status;
                }
                if (finalStatus !== 'granted') {
                    alert('Failed to get push token for push notification!');
                    return;
                }
                const token = await Notifications.getExpoPushTokenAsync();
                if (global.myPushToken != token) {
                    global.myPushToken = token;
                    console.log("Registering new Push Token:", token);

                    const requestObj = {
                        method: "POST",
                        path: "registerPushToken",
                        data: {
                            experienceID: Constants.manifest.id,
                            token,
                            myUID: global.uid,
                            idToken: global.idToken
                        }
                    }
                    console.log('inside notification ...')
                    const res = await AxiosCall(requestObj)
                    console.log(res);
                }
            } else {
                alert('Must use physical device for Push Notifications');
            }

        } catch (err) {
            console.log("Error setting Push Token", err);
        }
    };

    const handleNotification = (notification) => {
        const { data } = notification;
        // A simple example of passing data as the value
        // of the screen you want the user to be navigated to
        // when they click on a notification
        console.log(data, 'kkkkkkkk', notification);
        if (data.route) navigation.navigate(notification.data.route, notification.data.routeParams || {});
    };

    // useEffect(() => {


    // }, []);

    // useEffect(() => {
    //     const subscription = Notifications.addNotificationResponseReceivedListener(response => {
    //         console.log('response for notification', response);
    //         if (!response.notification.data) return;
    //         if (response.notification.data.route) {
    //             this.props.navigation.navigate(notification.data.route, notification.data.routeParams || {})
    //         }
    //     });
    //     return () => subscription.remove();
    // }, [navigation])
     

    const getLocationAsync = async () => {
        let { status } = await Permissions.askAsync(Permissions.LOCATION);
        if (status !== 'granted') {

            Alert.alert('Please enable location for this app',)
            return
        }

        let location = await Location.getCurrentPositionAsync({});
        console.log('location data is: ', location.coords || {})
        setLocation(location.coords)
        if (location.coords.latitude >= 4 && location.coords.latitude <= 14 && location.coords.longitude >= 2 && location.coords.longitude <= 15) {
            console.log('user is in valid area: ', location.coords.latitude, location.coords.longitude)
            setIsInValidArea(true)
        } else {
            setIsInValidArea(false)
        }

    };

    const fetchIdToken = async () => {
        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            //console.log('id tokennnn', idToken);
            // .currentUser.getIdToken(/* forceRefresh */ true);
            if (idToken) {
                global.idToken = idToken;
            } else {
                console.log("Check! fetchIdToken Error");
            }
        } catch (err) {
            console.log("ERROR fetching idToken", err);
        }
    };

    const setIDTokenListener = async () => {
        try {
            firebase.auth().onIdTokenChanged(function (user) {
                if (user) {
                    fetchIdToken();
                }
            });
        } catch (err) {
            console.log("ERROR setting idToken Listener", err);
        }
    };
    setIDTokenListener();

    useEffect(() => {
        getStoredData()
        showNotification();
        //global.setMyPushTokenIfNeeded()

        getUserDetails()

        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            console.log(notification);
        });

        responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            console.log(response);
        });

        return () => {
            Notifications.removeNotificationSubscription(notificationListener);
            Notifications.removeNotificationSubscription(responseListener);
        };
    }, [global.uid, global.myPushToken])

    const circle = (filled) => {
        if (filled) {
            return (
                <View
                    style={{
                        width: 20,
                        height: 20,
                        borderColor: "#206bdb",
                        //backgroundColor: "#0099ff",
                        borderWidth: 1,
                        borderRadius: 10,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <View
                        style={{
                            width: 14,
                            height: 14,
                            backgroundColor: "#206bdb",
                            borderRadius: 10,
                        }}
                    ></View>
                </View>
            );
        } else {
            return (
                <View
                    style={{
                        width: 20,
                        height: 20,
                        borderColor: "#2F4D86",
                        borderWidth: 1,
                        borderRadius: 10,
                    }}
                ></View>
            );
        }
    };

    const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const getRates = (num) => {
        const RATE_CONSTANTS = {
            0: 300,
            1: 400,
            2: 500,
            3: 600,
            4: 800,
            5: 1000,
            6: 1200,
            7: 1400,
            8: 1600,
            9: 1800,
            10: 2000
        }

        const rateChecker = (RATE) => {
            if (RATE <= 300) { return 300 }
            else if (RATE <= 400) { return 400 }
            else if (RATE <= 500) { return 500 }
            else if (RATE <= 600) { return 600 }
            else if (RATE <= 800) { return 800 }
            else if (RATE <= 1000) { return 1000 }
            else if (RATE <= 1200) { return 1200 }
            else if (RATE <= 1400) { return 1400 }
            else if (RATE <= 1600) { return 1600 }
            else if (RATE <= 1800) { return 1800 }
            else if (RATE <= 2000) { return 2000 }

            else if (RATE <= 2300) { return 2300 }
            else if (RATE <= 2400) { return 2400 }
            else if (RATE <= 2500) { return 2500 }
            else if (RATE <= 2600) { return 2600 }
            else if (RATE <= 2800) { return 2800 }
            else if (RATE <= 3000) { return 3000 }
            else if (RATE <= 3200) { return 3200 }
            else if (RATE <= 3400) { return 3400 }
            else if (RATE <= 3600) { return 3600 }
            else if (RATE <= 3800) { return 3800 }
            else if (RATE <= 4000) { return 4000 }
            else {
                let a = RATE.toString()
                let b = a.split('')
                if (b.length > 3) {
                    b[b.length - 1] = 0
                    b[b.length - 2] = 0
                    // console.log('b in ratechecker is ', b)
                }
                return parseFloat(b.join(''))
            }
        }
        // console.log('num received is ', num)

        getCharge()
        num = Math.round(realOrderAmt <= 50000 ? (0.02 * num) : (0.02 * num))
        let R = num.toString()
        let a = R.split('')
        a[a.length - 1] = 0
        // console.log('a in rates is ', a.join(''))
        let b = parseFloat(a.join(''))
        let FINALRATE = rateChecker(b)
        setRates(formatNumber(FINALRATE))
        return FINALRATE

    }

    const getCharge = () => {
        let a = 0
        if (realOrderAmt <= 50000) {
            a = 1000
            setCharges(1000)
        } else if (realOrderAmt <= 100000) {
            a = 1500
            setCharges(1500)
        } else if (realOrderAmt <= 200000) {
            a = 2000
            setCharges(2000)
        }/* else if (realOrderAmt <= 300000){
            setCharges(3000)
        }else if (realOrderAmt <= 400000){
            setCharges(4000)
        }else if (realOrderAmt <= 500000){
            setCharges(5000)
        } */else {
            a = 3000
            setCharges(3000)
        }
        console.log('realOrderAmt in getcharge ', realOrderAmt, ' charge is ', charges, ' a is ', a)
        return a
    }


    useEffect(() => {
        let a = getCharge()
    }, [realOrderAmt])


    const FadeInView = (props) => {
        const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

        React.useEffect(() => {
            Animated.timing(
                fadeAnim,
                {
                    toValue: 1,
                    duration: props.duration || 2000,
                    useNativeDriver: true
                }
            ).start();
        }, [fadeAnim])

        return (
            <Animated.View
                // Special animatable View
                style={{
                    ...props.style,
                    opacity: realOrderAmt >= 10000 ? fadeAnim : 0,         // Bind opacity to animated value
                }}
            >
                {props.children}
            </Animated.View>
        );
    }

    const createOrderRequest = async (data) => {
        try {
            console.log('this is the data sent for create order', data);
            const requestObj = {
                method: "POST",
                path: "createOrder",
                data: data,
            };
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {
                console.log('create order message', res);
                setModalVisible(false);
                await setOrderModalVisible(false)
                await setSignupModalVisible(false);
                setPageNum("1");
            } else {
                console.log('An error ocurred trying to create an order', res);
                Alert.alert('Something went wrong!')
            }
        }
        catch (e) {
            console.log(" firebase error ", e)
        }
    }

    const PayByPaystack = (props) => {
        return (
            <View style={{ width: '100%', alignItems: 'center', flex: 1 }}>
                <View style={{}}>

                    <PaystackWebView
                        buttonText="Pay Now"
                        showPayButton={false}
                        //paystackKey="pk_test_94ee9df047fb12b50589cd4840fbf948c1bfc4d7"
                        paystackKey="pk_live_22d10555a0972142954700f20815afe8e695eae3"
                        //paystackSecretKey="sk_test_e43c7bb778df82984d7f1c68c3178ac61658e5e4"
                        paystackSecretKey="sk_live_60a919ef1ad465f31b99704dd89c8ff69e5f785c"
                        amount={actualTotal}
                        billingEmail={email || "ordercashappng@gmail.com"}
                        billingMobile={phoneNumber || ""}
                        billingName={name || ""}
                        ActivityIndicatorColor="green"
                        SafeAreaViewContainer={{ marginTop: 5 }}
                        SafeAreaViewContainerModal={{ marginTop: 5 }}
                        handleWebViewMessage={(e) => {
                            console.log('webview message is ', e);
                            // handle the message
                        }}
                        onCancel={async (e) => {
                            console.log('cancel data is ', e);
                            setModalVisible(false);
                            setSignupModalVisible(false);
                            setOrderModalVisible(false)
                            await setPageNum("1")
                            await setRates(0)
                            await setTotal(0)
                            await setOrderAmt(0)
                            await setRealOrderAmt(0)
                            await setActualTotal(0)

                            //setPageNum("1")
                            // handle response here
                        }}
                        onSuccess={async (e) => {
                            console.log('payment', e)
                            // setPageNum("1");
                            let secretCode = Math.floor(Math.random() * 899999 + 100000);
                            // handle response here
                            let data = {
                                idToken: global.idToken,
                                userID: global.uid,
                                paymentDetails: e,
                                name: name,
                                phoneNumber: phoneNumber,
                                email: email,
                                address: nearestAddress,
                                area: area,
                                orderTotal: actualTotal,
                                orderAmount: realOrderAmt,
                                deliveryCharge: charges,
                                rate: rates,
                                paymentVendor: "Paystack",
                                secretCode
                            }
                            await createOrderRequest(data);
                            setOrderModalVisible(false)
                            setModalVisible(false);
                            setSignupModalVisible(false);
                            await setPageNum("1")
                            await setRates(0)
                            await setTotal(0)
                            await setOrderAmt(0)
                            await setRealOrderAmt(0)
                            await setActualTotal(0)
                            await setSignupModalVisible(false);
                            await setPaymentSuccessful(true);
                            navigation.navigate("Orders")
                        }}
                        //autoStart={false}
                        //autoStart={modalVisible}
                        //autoStart={pageNum == "6" ? true : false}
                        autoStart={true}
                        refNumber={generateReference()} // this is only for cases where you have a reference number generated
                        renderButton={(onPress) => {
                            <TouchableOpacity onPress={onPress}>
                                Pay Now
                            </TouchableOpacity>;
                        }} />
                </View>
                {/* <FirebaseRecaptchaVerifierModal
                    ref={recaptchaVerifier}
                    firebaseConfig={firebaseConfig}
                    visible={false} /> */}
            </View>
        );
    }

    const CollectAddressAndArea = (props) => {
        return (
            <View id='name' style={{ marginTop: 60, width: SCREEN_WIDTH * 0.9, alignItems: 'center', justifyContent: 'flex-start' }}>
                <View style={{
                    //backgroundColor: '#F0F0F0', 
                    width: SCREEN_WIDTH * 0.6,
                }}>
                    <Text style={{ textAlign: 'center', }}>Delivery Location</Text>
                </View>
                {/* ////////////////////     AREA AND NEAREST ADDRESS DISPLAY     ////////// */}
                {isAreaSelected && <TouchableOpacity onPress={() => { setIsAreaSelected(false); setArea("") }}
                    style={{
                        backgroundColor: '#D7F0FD', borderColor: '#206bdb', alignItems: 'center', justifyContent: 'center',
                        marginTop: 10, padding: 10, borderRadius: 20, borderWidth: 2,
                    }}>
                    <Text style={{//#F0F0F0
                        alignItems: 'center', flexDirection: 'row', color: '#206bdb', fontWeight: 'bold',
                        width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 0
                    }}>
                        {area}
                    </Text>
                </TouchableOpacity>}
                {isNearestAddressSelected && <TouchableOpacity onPress={() => { setIsNearestAddressSelected(false); setNearestAddress("") }}
                    style={{
                        backgroundColor: '#E0FBC6', borderColor: '#4E9905', alignItems: 'center', justifyContent: 'center',
                        marginTop: 10, padding: 10, borderRadius: 20, borderWidth: 1.5,
                    }}>
                    <Text style={{
                        alignItems: 'center', flexDirection: 'row', color: '#4E9905',
                        width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 0
                    }}>
                        {nearestAddress}
                    </Text>
                </TouchableOpacity>}
                {/* ////////////////////     AREA AND NEAREST ADDRESS DISPLAY     ////////// */}

                <View style={{ width: '70%', paddingLeft: 30, marginTop: 10 }}>

                    {!isAreaSelected && AREAS.map((item, id) => {
                        return (
                            <TouchableOpacity key={id}
                                style={{
                                    flexDirection: "row", justifyContent: "flex-start",
                                    alignItems: "center", marginVertical: 10, marginLeft: 5,
                                }}
                                onPress={async () => {
                                    await setArea(item.value);
                                    await setNearestAddress('')
                                    await setIsNearestAddressSelected(false)
                                    await setIsAreaSelected(item.value == 'n/a' ? false : true)
                                    // console.log("area is now ", area)
                                }}
                            >
                                {circle(area == `${item.value}` ? true : false)}
                                <Text style={{
                                    color: "#FCD07F", fontSize: 12, color: "#206bdb", marginTop: 4,
                                    marginLeft: 10, fontSize: 18, fontFamily: "TTCommons-Regular",

                                }}
                                >
                                    {item.label}
                                </Text>
                            </TouchableOpacity>
                        )
                    })}

                    {(area == "Lekki" || area == 'Ikoyi' || area == 'Victoria Island') && (!isAreaSelected || !isNearestAddressSelected) && <View
                        style={{
                            marginVertical: 20,
                            marginTop: 10,
                            width: '100%',
                            marginLeft: -10,
                            backgroundColor: '#F0F0F0',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <RNPickerSelect
                            placeholder={{
                                label: "Select nearest location ",
                                value: nearestAddress,
                                color: "red",
                            }}
                            items={selectedArea[area]}
                            onValueChange={async (value) => {
                                console.log('selected nearestaddress is: ', nearestAddress)
                                await setNearestAddress(value)
                                await setIsNearestAddressSelected(true)
                            }}
                            style={{
                                ...pickerSelectStyles,
                                iconContainer: {
                                    top: 15,
                                    right: 10,
                                },
                                placeholder: {
                                    color: "#1A315C",
                                    fontSize: 16,
                                    // fontWeight: "bold",
                                    fontFamily: "TTCommons-Regular",
                                },
                            }}
                            value={nearestAddress}
                            Icon={() => {
                                return (
                                    <View
                                        style={{
                                            backgroundColor: "transparent",
                                            borderTopWidth: 10,
                                            borderTopColor: "gray",
                                            borderRightWidth: 10,
                                            borderRightColor: "transparent",
                                            borderLeftWidth: 10,
                                            borderLeftColor: "transparent",
                                            width: 0,
                                            height: 0,
                                        }}
                                    />
                                );
                            }}
                        />

                    </View>}
                </View>

                <View style={{
                    marginTop: 10, alignItems: 'center', flexDirection: 'row',
                    //backgroundColor:'#F0F0F0',
                    width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between',
                }}>


                    <TouchableOpacity onPress={async () => {
                        if (savedDataPresent) {
                            const userData = await AsyncStorage.getItem("userData");
                            //const idToken = await AsyncStorage.getItem("idToken");
                            const Data = await JSON.parse(userData);
                            //console.log('parsed data in localstorage is ', userData, 'Data ', Data)
                            if (Data !== null) {
                                setNearestAddress(Data.locations[0].address)
                                setArea(Data.locations[0].area)
                            }
                        }
                        setPageNum(props.back)
                    }}
                        style={{
                            padding: 6, paddingHorizontal: 13, backgroundColor: '#FFDCE9', borderRadius: 20, marginTop: 10,
                            alignSelf: 'center', borderWidth: 2, borderColor: '#FF7AA9'
                        }}>
                        <Text style={{ textAlign: (nearestAddress !== '') ? 'center' : 'left', color: '#FF7AA9', fontSize: 12 }}> ← Go Back</Text>
                    </TouchableOpacity>
                    {(area == 'n/a') || (area !== '') && (nearestAddress !== '') && <TouchableOpacity
                        onPress={() => { setPageNum(props.next) }}
                        style={{
                            padding: 6, paddingHorizontal: 20, backgroundColor: '#E0FBC6', borderRadius: 20,
                            marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#4E9905'
                        }}>
                        <Text style={{
                            textAlign: 'right', marginLeft: 10, color: '#4E9905', fontSize: 12
                        }}> Next →</Text>
                    </TouchableOpacity>}
                </View>

                {area == 'n/a' && <View style={{ width: SCREEN_WIDTH * 0.7, alignItems: 'center', marginTop: 20, }}>
                    <Text style={{
                        color: "#FF7AA9", fontSize: 12, marginTop: 20,
                        marginLeft: 10, fontSize: 22, fontFamily: "TTCommons-Regular",
                        textAlign: 'center'
                    }}
                    >
                        Omo! delivery is not possible for now. 😢
                                </Text>
                    <View style={{ marginTop: 10, width: "90%", justifyContent: 'center' }}>
                        <Text style={{ textAlign: 'center', color: '#4E9905', lineHeight: 25 }}>{deliveryDisclaimerMessage}</Text>
                    </View>
                </View>
                }
            </View>
        )
    }

    // const CollectName = (props) => {
    //     return (
    //         <View style={{ marginTop: 100, width: '100%', alignItems: 'center', }}>
    //             <View id='name' style={{ width: '100%', alignItems: 'center' }}>
    //                 <Text style={{ textAlign: 'center' }}>Name</Text>
    //                 <TextInput
    //                     style={{
    //                         color: "#1A315C",
    //                         fontFamily: "TTCommons-Regular",
    //                         fontSize: 17,
    //                         width: '70%',
    //                         marginTop: 5,
    //                         minHeight: 30,
    //                         borderBottomColor: "#206bdb",
    //                         borderBottomWidth: 2,
    //                         textAlign: 'center'
    //                     }}
    //                     placeholder="Who's making the order?"
    //                     value={name}
    //                     onChangeText={(val) => {
    //                         let val2 = val.trim()
    //                         var regex = /^[a-zA-Z\_]+$/
    //                         // console.log('input is ', val2, ' name is ', name)
    //                         //let val = (text == "")? true: regex.test(text)
    //                         let text = regex.test(val2)
    //                         // console.log('text is ', text)
    //                         if (text) {
    //                             setName(val2);
    //                             setNameError("")
    //                         } else {
    //                             //console.log('blah blah blah')
    //                             setNameError("*Oops! only alphabets are allowed!*")
    //                             //setName('');
    //                         }

    //                     }}
    //                     //keyboardType="alphanumeric"
    //                     autoCompleteType="tel"
    //                 />
    //             </View>

    //             {<View style={{ marginTop: 10 }}><Text style={{ fontSize: 10, color: '#FF7AA9' }}>{nameError}</Text></View>}
    //             <View style={{ marginTop: 20, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-evenly' }}>
    //                 <TouchableOpacity onPress={() => { setPageNum(props.back) }}
    //                     style={{
    //                         padding: 5, paddingHorizontal: 10, backgroundColor: '#FFDCE9', borderRadius: 20,
    //                         marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#FF7AA9'
    //                     }}>
    //                     <Text style={{ textAlign: 'center', color: '#FF7AA9' }}> ← Go Back</Text>
    //                 </TouchableOpacity>
    //                 {name.length >= 3 && <TouchableOpacity onPress={() => { setPageNum(props.next) }} style={{
    //                     padding: 5, paddingHorizontal: 20, backgroundColor: '#E0FBC6', borderRadius: 20,
    //                     marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#4E9905'
    //                 }}>
    //                     <Text style={{ textAlign: 'center', color: '#4E9905' }}> Next →</Text>
    //                 </TouchableOpacity>}
    //             </View>
    //         </View>
    //     )
    // }

    const ShowTotalOrder = (props) => {
        return (
            <View style={{ marginTop: 100, width: '100%', alignItems: 'center', }}>
                <View id='total' style={{ width: '70%', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ color: 'grey', fontSize: 16 }}> Order Details</Text>
                    <View style={{ alignItems: 'center', justifyContent: 'center', marginVertical: 10, }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', lineHeight: 20 }}>{name || ""}</Text>
                        <Text style={{ fontSize: 15, lineHeight: 20 }}>{nearestAddress || ""}</Text>
                        <Text style={{ fontSize: 15, lineHeight: 20 }}>{area || ""}</Text>
                        <Text style={{ fontSize: 15, lineHeight: 20 }}>{phoneNumber || "..."}</Text>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            Order :
                                </Text>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 18,
                            padding: 2,
                            margin: 2,
                        }}>
                            N{orderAmt}
                        </Text>

                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "red",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            rate :
                                </Text>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            N{rates}
                        </Text>

                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "green",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            Convenience Charge :
                                </Text>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            N{charges}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={{
                            fontFamily: "TTCommons-Medium",
                            color: "#206bdb",
                            fontSize: 18,
                            padding: 2,
                            margin: 2,
                        }}>
                            Total:
                                </Text>
                        <Text style={{
                            fontFamily: "TTCommons-Medium",
                            color: "#206bdb",
                            fontSize: 18,
                            padding: 2,
                            margin: 2,
                        }}>
                            N {total}
                        </Text>
                    </View>

                    <PaystackConsumer {...componentProps} >
                        {({ initializePayment }) => <TouchableOpacity id="order-button"
                            style={{
                                backgroundColor: '#D7F0FD', alignSelf: "center", padding: 10, paddingHorizontal: 30,
                                justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: 20,
                                borderWidth: 2, borderColor: '#206bdb'
                            }}
                            onPress={() => {
                                if (phoneNumber == '+98765432100') {
                                    setOrderModalVisible(false)
                                    setModalVisible(false)
                                    setSignupModalVisible(false)
                                    navigation.navigate("Orders");
                                    return
                                }
                                //setPageNum(props.next)
                                initializePayment(handleSuccess, handleClose)
                            }}>
                            <Text style={{ color: '#206bdb', fontWeight: "bold", fontSize: 14 }}>ORDER {" "} N{total}</Text>
                        </TouchableOpacity>}
                    </PaystackConsumer>
                    {/* {phoneNumber == '+98765432100' && <TouchableOpacity id="order-button"
                        style={{
                            backgroundColor: '#D7F0FD', alignSelf: "center", padding: 10, paddingHorizontal: 30,
                            justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: 20,
                            borderWidth: 2, borderColor: '#206bdb'
                        }}
                        onPress={() => {  navigation.navigate("Orders"); setOrderModalVisible(false)}}>
                        <Text style={{ color: '#206bdb', fontWeight: "bold", fontSize: 14 }}>ORDER {" "} N{total}</Text>
                    </TouchableOpacity>} */}


                    <TouchableOpacity onPress={() => { setPageNum(props.back) }}
                        style={{
                            padding: 5, justifyContent: 'center', marginTop: 30, paddingHorizontal: 10, backgroundColor: '#FFDCE9',
                            borderRadius: 20, alignSelf: 'center', borderWidth: 2, borderColor: '#FF7AA9'
                        }}
                    >
                        <Text style={{ textAlign: 'left', color: '#FF7AA9' }}> ← Go Back</Text>
                    </TouchableOpacity>
                </View>

            </View>
        )
    }

    return (
        <View
            style={{
                backgroundColor: "#f7fbfd",
                height: SCREEN_HEIGHT,
                //minHeight: 1500,
                width: SCREEN_WIDTH,
                alignItems: "center",
                flex: 1, justifyContent: 'center',
                position: 'relative',
            }}
        >
            <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />

            <ScrollView
                // keyboardShouldPersistTaps='handled'
                // keyboardShouldPersistTaps='always'
                contentContainerStyle={{
                    padding: 20,
                    alignItems: "center",
                    flex: 1, justifyContent: 'center'
                }}>
                <View style={{ height: 100 }}>

                </View>

                <Text style={{
                    fontFamily: "TTCommons-Medium",
                    color: "#206bdb",
                    fontSize: 24,
                    padding: 2,
                    margin: 2,
                    fontWeight: 'bold',
                }}>
                    How much do you need?
                </Text>
                <TextInput
                    style={{
                        color: "#1A315C",
                        fontFamily: "TTCommons-Regular",
                        fontSize: 30,
                        marginVertical: 20,
                        minHeight: 50,
                        width: "80%",
                        height: 20,
                        borderBottomColor: "#206bdb",
                        borderBottomWidth: 2,
                        textAlign: 'center'
                    }}
                    placeholder="10,000 ?"
                    keyboardType={'number-pad'}
                    onChangeText={(text) => {
                        if (text === NaN) { return }
                        if (text == '') { setTotal(0); setRates(0), setOrderAmt(0), setRealOrderAmt(0), setActualTotal(0) }
                        if (text && text.length > 2 && parseFloat(text) >= 10000 && parseFloat(text) <= 5000000) {
                            //if (text && text.length > 1 && parseFloat(text) >= 100 && parseFloat(text) <= 5000000) {
                            let CHARGE = getCharge()
                            let a = text.split('')
                            let total = 0
                            let subtotal = 0
                            a[a.length - 1] = 0
                            a[a.length - 2] = 0
                            console.log('a is ', a.join(''))
                            let b = a.join('')
                            let r = getRates(Math.round(parseFloat(b)))
                            setOrderAmt(formatNumber(parseFloat(b)))
                            setRealOrderAmt(parseFloat(b))
                            total = Math.round(parseFloat(b) + CHARGE + r)
                            subtotal = Math.round(parseFloat(b) + r)
                            setSubTotal(formatNumber(subtotal))
                            setActualTotal(total)
                            setTotal(formatNumber(total))
                        } else {
                            setRates(0)
                            setTotal(0)
                            setOrderAmt(0)
                            setRealOrderAmt(0)
                            setActualTotal(0)
                        }

                    }}
                />






                <FadeInView style={{ opacity: fadeAnim, alignItems: "center", }} duration={500}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "red",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            rate :
                        </Text>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            N{rates}
                        </Text>

                    </View>
                </FadeInView>

                <FadeInView style={{ opacity: fadeAnim, alignItems: "center" }} duration={500}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            Valid Order Amt :
                        </Text>
                        <Text style={{
                            fontFamily: "TTCommons-Regular",
                            color: "grey",
                            fontSize: 14,
                            padding: 2,
                            margin: 2,
                        }}>
                            N{orderAmt}
                        </Text>
                    </View>

                    <Text style={{
                        fontFamily: "TTCommons-ExtraBold",
                        color: "#206bdb",
                        fontSize: 16,
                        padding: 2,
                        marginTop: 20,
                    }}>
                        Sub-Total:
                </Text>
                    <Text style={{
                        fontFamily: "TTCommons-Regular",
                        color: "#206bdb",
                        fontSize: 24,
                        padding: 2,
                        margin: 2,
                    }}>
                        N{subtotal}
                    </Text>
                </FadeInView>

                <FadeInView style={{ opacity: fadeAnim, width: '80%', alignItems: "center" }} duration={500}>
                    {total !== 0 && <TouchableOpacity id="order-button"
                        style={{
                            backgroundColor: '#D7F0FD', alignSelf: "center", padding: 10, paddingHorizontal: 30,
                            justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: 20,
                            borderWidth: 2, borderColor: '#206bdb'
                        }}
                        onPress={async () => {
                            //setSignupModalVisible(true)
                            //await getLocationAsync()
                            //if(!isInValidArea && Platform.OS === 'ios') return
                            savedDataPresent ? setOrderModalVisible(true) : setSignupModalVisible(true)
                        }}>
                        <Text style={{ color: '#206bdb', fontWeight: "bold", fontSize: 12 }}>ORDER CASH</Text>
                    </TouchableOpacity>}
                </FadeInView>


            </ScrollView>
            <View style={{ height: 100 }}>

            </View>






            {/* /////////////////////////////////////ORDER MODAL//////////////////////////////////*/}
            <Modal
                animationType="slide"
                transparent={true}
                visible={orderModalVisible}
                onRequestClose={() => {
                    setOrderModalVisible(false)
                    //this.setState({ modalAVisible: false });
                }}
            >
                <View style={{
                    position: "relative", bottom: 0, left: 0, height: "100%", width: SCREEN_WIDTH, flex: 1,
                    alignItems: "center", backgroundColor: "rgba(255,255,255,1)",
                    padding: 20, alignSelf: "center", borderTopEndRadius: 20,
                }}>
                    <ScrollView
                        contentContainerStyle={{
                            //justifyContent:'center',
                            alignItems: 'center',
                            flex: 1,
                            position: 'relative',
                            height: '100%',
                            width: SCREEN_WIDTH * 0.9,
                            marginTop: Constants.statusBarHeight,
                        }}>
                        {/* ////////////////////     AREA AND NEAREST ADDRESS DISPLAY IN ORDER MODAL   ////////// */}
                        {pageNum == "1" && <View id='confirm-delivery-details' style={{ marginTop: 50, width: SCREEN_WIDTH * 0.7, alignItems: 'center', }}>
                            <Text> Delivery details</Text>
                            <View style={{ marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={{ fontSize: 15, fontWeight: 'bold', lineHeight: 20 }}>{name || "..."}</Text>
                                <Text style={{ fontSize: 15, lineHeight: 20 }}>{nearestAddress || "..."}</Text>
                                <Text style={{ fontSize: 15, lineHeight: 20 }}>{area || "..."}</Text>
                                <TouchableOpacity onPress={() => {
                                    setUseNewAddress(true);
                                    setPageNum("3")
                                    //setArea("") 
                                }}
                                    style={{
                                        backgroundColor: '#D7F0FD', borderColor: '#206bdb', alignItems: 'center', justifyContent: 'center',
                                        marginTop: 10, padding: 10, borderRadius: 20, borderWidth: 2,
                                    }}>
                                    <Text style={{//#F0F0F0
                                        alignItems: 'center', flexDirection: 'row', color: '#206bdb', fontWeight: 'bold',
                                        width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 0
                                    }}>
                                        Yes, use this
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => { setPageNum("2") }}
                                    style={{
                                        backgroundColor: '#FFDCE9', borderColor: '#206bdb', alignItems: 'center', justifyContent: 'center',
                                        marginTop: 10, padding: 10, borderRadius: 20, borderWidth: 2,
                                    }}>
                                    <Text style={{//#F0F0F0
                                        alignItems: 'center', flexDirection: 'row', color: '#206bdb', fontWeight: 'bold',
                                        width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 0
                                    }}>
                                        No, use somewhere else
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>}

                        {pageNum == "2" && <CollectAddressAndArea back={"1"} next={"3"} />}
                        {/* {pageNum == "2" && <View style={{
                            marginTop: 10, alignItems: 'center', flexDirection: 'row', alignSelf: 'center',
                            width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between', marginLeft: -10
                        }}>
                            <TouchableOpacity onPress={() => { }}
                                style={{
                                    padding: 6, paddingHorizontal: 16, backgroundColor: '#FFDCE9', borderRadius: 20,
                                    borderWidth: 2, borderColor: '#FF7AA9', borderBottomRightRadius: 0, borderTopRightRadius: 0
                                }}>
                                <Text style={{ textAlign: 'center', color: '#FF7AA9', fontSize: 12 }}> Save Location</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => { }}
                                style={{
                                    padding: 6, paddingHorizontal: 16, backgroundColor: '#FFDCE9', borderRadius: 20,
                                    borderWidth: 2, borderColor: '#FF7AA9', borderBottomLeftRadius: 0, borderTopLeftRadius: 0
                                }}>
                                <Text style={{ textAlign: 'center', color: '#FF7AA9', fontSize: 12 }}> No, Use Once</Text>
                            </TouchableOpacity>
                        </View>} */}
                        {/* ////////////////////     AREA AND NEAREST ADDRESS DISPLAY IN ORDER MODAL    ////////// */}

                        {pageNum == "3" && <ShowTotalOrder back={'1'} next={'4'} />}
                        {pageNum == "4" && <PaystackConsumer {...componentProps} >
                            {({ initializePayment }) => <TouchableOpacity id="order-button"
                                style={{
                                    backgroundColor: '#D7F0FD', alignSelf: "center", padding: 10, paddingHorizontal: 30,
                                    justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: 20,
                                    borderWidth: 2, borderColor: '#206bdb'
                                }}
                                onPress={() => {
                                    if (phoneNumber == '+98765432100') {
                                        setOrderModalVisible(false)
                                        setModalVisible(false)
                                        setSignupModalVisible(false)
                                        navigation.navigate("Orders");
                                        return
                                    }
                                    //setPageNum(props.next)
                                    initializePayment(handleSuccess, handleClose)
                                }}>
                                <Text style={{ color: '#206bdb', fontWeight: "bold", fontSize: 14 }}>ORDER {" "} N{total}</Text>
                            </TouchableOpacity>}
                        </PaystackConsumer>}


                        <View style={{ width: '100%', justifyContent: 'center', position: 'absolute', top:10,  
                        //bottom: 70 
                        }}>
                            <HideWithKeyboard>
                                <TouchableOpacity
                                    style={{
                                        padding: 14,
                                        paddingHorizontal: 40,
                                        //backgroundColor: '#F4F2F2',
                                        borderRadius: 30,
                                        alignSelf: 'center',
                                        //borderWidth: 2,
                                        //borderColor: '#d3d3d3'
                                    }}
                                    onPress={async () => {
                                        await setPageNum("1");
                                        //await setArea("")
                                        //await setNearestAddress("")
                                        await setIsAreaSelected(false)
                                        await setIsNearestAddressSelected(false)
                                        await setOrderModalVisible(false)
                                    }}>
                                    <Text style={{ textAlign: 'center', color: '#767676', fontSize: 11, fontWeight: 'bold' }}>X Cancel</Text>
                                </TouchableOpacity>
                            </HideWithKeyboard>
                        </View>

                    </ScrollView>
                </View>

            </Modal>

            {/* /////////////////////////////// SIGNUP MODAL ////////////////////////*/}
            <Modal

                animationType="slide"
                transparent={true}
                visible={signupModalVisible}
                onRequestClose={() => {
                    setSignupModalVisible(false)
                    //this.setState({ modalAVisible: false });
                }}
            >

                <View style={{
                    position: "relative", bottom: 0, left: 0, height: "100%", width: SCREEN_WIDTH, flex: 1,
                    alignItems: "center", backgroundColor: "rgba(255,255,255,1)",
                    padding: 20, alignSelf: "center", borderTopEndRadius: 20,
                }}>
                    <ScrollView
                        // keyboardShouldPersistTaps='handled'
                        keyboardShouldPersistTaps={'always'}
                        contentContainerStyle={{
                            //justifyContent:'center',
                            alignItems: 'center',
                            flex: 1,
                            position: 'relative',
                            height: '100%',
                            width: SCREEN_WIDTH * 0.9,
                            marginTop: Constants.statusBarHeight,
                        }}>
                        {/* {pageNum == "1" && <CollectPhoneNumber next={'2'} />} */}
                        {pageNum == "1" && <View id='phone' style={{ marginTop: 100, width: SCREEN_WIDTH * 0.9, alignItems: 'center' }}>
                            <Text style={{ textAlign: 'center' }}>Phone Number</Text>
                            <TextInput
                                style={{
                                    color: "#1A315C",
                                    fontFamily: "TTCommons-Regular",
                                    fontSize: 18,
                                    marginVertical: 20,
                                    minHeight: 50,
                                    width: "60%",
                                    height: 20,
                                    borderBottomColor: "#206bdb",
                                    borderBottomWidth: 2,
                                    textAlign: 'center'
                                }}
                                placeholder="This is how we contact you"
                                onChangeText={(text) => {
                                    console.log('text entered is ', text)
                                    setPhoneNumber(text)
                                }}
                                value={phoneNumber}
                                keyboardType="phone-pad"
                            // autoCompleteType="tel"
                            />
                            <TouchableOpacity onPress={async () => {
                                await signin(phoneNumber, verficationMethod === 'SMS' ? 'sms' : 'whatsapp')
                            }}
                                style={{
                                    padding: 10,
                                    width: 150,
                                    //backgroundColor: 'rgba(38, 194, 129, 1)',
                                    backgroundColor: '#E0FBC6',
                                    borderRadius: 20,
                                    marginTop: 10,
                                    alignSelf: 'center',
                                    borderWidth: 2,
                                    borderColor: '#4E9905'
                                }}
                                disabled={confirmCodeActivity}>
                                <Text style={{ textAlign: 'center', color: '#4E9905', fontWeight: 'bold' }}>Verify </Text>
                            </TouchableOpacity>

                            <View id='verification-method' style={{ marginTop: 10 }}>
                                {/* <TouchableOpacity
                                    style={{
                                        flexDirection: "row", justifyContent: "flex-start",
                                        alignItems: "center", marginVertical: 10,
                                    }}
                                    onPress={async () => {
                                        setVerificationMethod('Whatsapp')
                                    }}
                                >
                                    {circle(verficationMethod == 'Whatsapp' ? true : false)}
                                    <Text style={{
                                        fontSize: 12, color: "#4E9905", marginTop: 4,
                                        marginLeft: 10, fontSize: 18, fontFamily: "TTCommons-Regular",

                                    }}
                                    >
                                        Whatsapp
                                </Text>
                                </TouchableOpacity> */}
                                <TouchableOpacity
                                    style={{
                                        flexDirection: "row", justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                    onPress={async () => {
                                        setVerificationMethod('SMS')
                                    }}
                                >
                                    {circle(verficationMethod == 'SMS' ? true : false)}
                                    <Text style={{
                                        color: "#FCD07F", fontSize: 12, color: "#4E9905", marginTop: 4,
                                        marginLeft: 10, fontSize: 18, fontFamily: "TTCommons-Regular",

                                    }}
                                    >
                                        SMS
                                </Text>
                                </TouchableOpacity>
                                {confirmCodeActivity && <View style={{
                                    backgroundColor: '#FFF',
                                    marginTop: 10, alignSelf: 'center', flex: 1, width: '100%', height: '100%'
                                }}
                                >
                                    <View>
                                        <ActivityIndicator
                                            size="large"
                                            color="#206bdb"
                                            style={{
                                                opacity: 1,
                                                marginVertical: 10
                                            }}
                                            animating={true}
                                        />
                                    </View>

                                </View>}
                            </View>


                        </View>}

                        {/* {pageNum == "2" && <ConfirmPhoneNumber back={'1'} next={'3'} />} */}
                        {pageNum == "2" && <View id='confirm-number' style={{ marginTop: 100, width: SCREEN_WIDTH * 0.7, alignItems: 'center', }}>
                            <Text style={{ textAlign: 'center' }}>Please enter the verification code sent to your Phone Number</Text>
                            <TextInput
                                style={{
                                    color: "#1A315C",
                                    fontFamily: "TTCommons-Regular",
                                    fontSize: 16,
                                    marginVertical: 20,
                                    minHeight: 50,
                                    width: "60%",
                                    height: 20,
                                    borderBottomColor: "#206bdb",
                                    borderBottomWidth: 2,
                                    textAlign: 'center'
                                }}
                                placeholder="Confirmation Code"
                                value={code}
                                onChangeText={text => setCode(text)}
                                keyboardType="number-pad"
                            />
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <TouchableOpacity disabled={confirmCodeActivity} onPress={() => { setPageNum("1") }}
                                    style={{
                                        padding: 6, paddingHorizontal: 20, backgroundColor: '#FFDCE9', borderRadius: 20, marginTop: 10,
                                        alignSelf: 'center', borderWidth: 2, borderColor: '#FF7AA9'
                                    }}>
                                    <Text style={{ color: '#E5266A', fontSize: 13 }}>Go Back</Text>
                                </TouchableOpacity>
                                <TouchableOpacity disabled={confirmCodeActivity} onPress={async () => {
                                    await confirmCode()
                                    // if (!setConfirmCodeActivity) {
                                    // setPageNum("3")
                                    // }

                                }}
                                    style={{
                                        padding: 6, paddingHorizontal: 20, backgroundColor: '#E0FBC6', borderRadius: 20,
                                        marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#4E9905'
                                    }}
                                >
                                    <Text style={{ color: '#4E9905', fontSize: 13 }}>Confirm</Text>
                                </TouchableOpacity>

                            </View>



                            {/* {confirmCodeActivity && <View style={{ */}
                            {confirmCodeActivity && <View style={{
                                backgroundColor: '#FFF',
                                marginTop: 10, alignSelf: 'center', flex: 1, width: '100%', height: '100%'
                            }}
                            >
                                <View>
                                    <ActivityIndicator
                                        size="large"
                                        color="#206bdb"
                                        style={{
                                            opacity: 1,
                                            marginVertical: 10
                                        }}
                                        animating={true}
                                    />
                                </View>

                            </View>}

                        </View>}
                        {pageNum == "3" && <CollectAddressAndArea back={'2'} next={'4'} />}
                        {pageNum == "4" && <View style={{ marginTop: 100, width: '100%', alignItems: 'center', }}>
                            <View id='name' style={{ width: '100%', alignItems: 'center' }}>
                                <Text style={{ textAlign: 'center' }}>Name</Text>
                                <TextInput
                                    style={{
                                        color: "#1A315C",
                                        fontFamily: "TTCommons-Regular",
                                        fontSize: 17,
                                        width: '70%',
                                        marginTop: 5,
                                        minHeight: 30,
                                        borderBottomColor: "#206bdb",
                                        borderBottomWidth: 2,
                                        textAlign: 'center'
                                    }}
                                    placeholder="Who's making the order?"
                                    value={name}
                                    onChangeText={(val) => {
                                        let val2 = val.trim()
                                        var regex = /^[a-zA-Z\_]+$/
                                        // console.log('input is ', val2, ' name is ', name)
                                        //let val = (text == "")? true: regex.test(text)
                                        let text = regex.test(val2)
                                        // console.log('text is ', text)
                                        if (text) {
                                            setName(val2);
                                            setNameError("")
                                        } else {
                                            //console.log('blah blah blah')
                                            setNameError("*Oops! only alphabets are allowed!*")
                                            //setName('');
                                        }

                                    }}
                                    //keyboardType="alphanumeric"
                                    autoCompleteType="tel"
                                />
                            </View>

                            {<View style={{ marginTop: 10 }}><Text style={{ fontSize: 10, color: '#FF7AA9' }}>{nameError}</Text></View>}
                            <View style={{ marginTop: 20, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                {phoneNumber !== '+98765432100' && <TouchableOpacity onPress={() => { setPageNum("3") }}
                                    style={{
                                        padding: 5, paddingHorizontal: 10, backgroundColor: '#FFDCE9', borderRadius: 20,
                                        marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#FF7AA9'
                                    }}>
                                    <Text style={{ textAlign: 'center', color: '#FF7AA9' }}> ← Go Back</Text>
                                </TouchableOpacity>}
                                {name.length >= 3 && <TouchableOpacity onPress={() => { setPageNum("5") }} style={{
                                    padding: 5, paddingHorizontal: 20, backgroundColor: '#E0FBC6', borderRadius: 20,
                                    marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#4E9905'
                                }}>
                                    <Text style={{ textAlign: 'center', color: '#4E9905' }}> Next →</Text>
                                </TouchableOpacity>}
                            </View>
                        </View>}
                        {pageNum == "5" && <View style={{ marginTop: 100, width: '100%', alignItems: 'center', }}>
                            <View id='enter-email' style={{ width: '100%', alignItems: 'center' }}>
                                <Text style={{ textAlign: 'center' }}>Email</Text>
                                <TextInput
                                    style={{
                                        color: "#1A315C",
                                        fontFamily: "TTCommons-Regular",
                                        fontSize: 17,
                                        width: '70%',
                                        marginTop: 5,
                                        minHeight: 30,
                                        borderBottomColor: "#206bdb",
                                        borderBottomWidth: 2,
                                        textAlign: 'center'
                                    }}
                                    placeholder="We'll send you a receipt here"
                                    value={email}
                                    onChangeText={(val) => {
                                        let text = val.trim()
                                        //var regex = /^[a-zA-Z\_]+$/
                                        // console.log('input is ', val2, ' name is ', name)
                                        //let val = (text == "")? true: regex.test(text)
                                        //let text = regex.test(val2)
                                        // console.log('text is ', text)
                                        if (text) {
                                            setEmail(text);
                                            setEmailError("")
                                        } else {
                                            //console.log('blah blah blah')
                                            setEmailError("*Oops! only alphabets are allowed!*")
                                            //setName('');
                                        }

                                    }}
                                    keyboardType="email-address"
                                    autoCompleteType="tel"
                                />
                            </View>

                            {<View style={{ marginTop: 10 }}><Text style={{ fontSize: 10, color: '#FF7AA9' }}>{emailError}</Text></View>}
                            <View style={{ marginTop: 20, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <TouchableOpacity onPress={() => { setPageNum("4") }}
                                    style={{
                                        padding: 5, paddingHorizontal: 10, backgroundColor: '#FFDCE9', borderRadius: 20,
                                        marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#FF7AA9'
                                    }}>
                                    <Text style={{ textAlign: 'center', color: '#FF7AA9' }}> ← Go Back</Text>
                                </TouchableOpacity>
                                {name.length >= 3 && <TouchableOpacity onPress={() => { setPageNum("6") }} style={{
                                    padding: 5, paddingHorizontal: 20, backgroundColor: '#E0FBC6', borderRadius: 20,
                                    marginTop: 10, alignSelf: 'center', borderWidth: 2, borderColor: '#4E9905'
                                }}>
                                    <Text style={{ textAlign: 'center', color: '#4E9905' }}> Next →</Text>
                                </TouchableOpacity>}
                            </View>
                        </View>}
                        {pageNum == "6" && <ShowTotalOrder back={'5'} next={'7'} />}

                        {/* {pageNum == "7" && <PayByPaystack />} */}

                        <View style={{
                            width: '100%', justifyContent: 'center', position: 'absolute', top: 10,
                            //bottom:70
                        }}>
                            <HideWithKeyboard>
                                <TouchableOpacity
                                    style={{
                                        padding: 14,
                                        paddingHorizontal: 40,
                                        //backgroundColor: '#F4F2F2',
                                        borderRadius: 30,
                                        alignSelf: 'center',
                                        //borderWidth: 2,
                                        //borderColor: '#d3d3d3'
                                    }}
                                    onPress={async () => {
                                        await setPageNum("1");
                                        // await setArea("")
                                        // await setNearestAddress("")
                                        await setIsAreaSelected(false)
                                        await setOrderModalVisible(false)
                                        await setIsNearestAddressSelected(false)
                                        await setSignupModalVisible(false)
                                    }}>
                                    <Text style={{ textAlign: 'center', color: '#767676', fontSize: 11, fontWeight: 'bold' }}>X Cancel</Text>
                                </TouchableOpacity>
                            </HideWithKeyboard>
                        </View>
                    </ScrollView>
                </View>
            </Modal>





        </View>
    );
};
export default HomeScreen;
const style = {
    Text1: {
        fontFamily: "TTCommons-ExtraBold",
        color: "#1A315C",
        fontSize: 16,
    },
    Text2: {
        fontFamily: "TTCommons-Regular",
        color: "#FCD07F",
        fontSize: 16,
    },
};




const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        fontSize: 13,
        paddingVertical: 5,
        paddingTop: 8,
        paddingHorizontal: 10,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: '#2F4D86',
        borderRadius: 4,
        backgroundColor: '#FFF',
        color: 'black',
        width: '98%',
    },
    inputANDROID: {
        fontSize: 13,
        paddingVertical: 5,
        paddingTop: 8,
        paddingHorizontal: 10,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: '#2F4D86',
        borderRadius: 4,
        backgroundColor: '#FFF',
        color: 'black',
        width: '98%'
    },
});