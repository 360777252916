import React from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
} from 'react-native';

import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';

import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/auth';
const auth = firebase.auth();
const CustomSidebarMenu = (props) => { 
const logo = 'https://firebasestorage.googleapis.com/v0/b/order-cash-ee263.appspot.com/o/icon-android.png?alt=media&token=4f0251d5-7a4b-495e-901a-71dbe5556150'
  return (
    <SafeAreaView style={{flex: 1}}>
      {/*Top Large Image */}
      <View style={{flexDirection:'row',  alignItems:'center'}}>
      <Image
        source={{uri: logo}}
        style={styles.sideMenuProfileIcon}
      />
      <View style={{marginLeft:10, marginTop:10}}>
          <Text style={{fontWeight:'bold'}}>OrderCash Orders</Text>
      </View>
      </View>
     
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
        <DrawerItem
          label="Visit Us"
          //onPress={() => Linking.openURL('https://aboutreact.com/')}
        />
        <View style={styles.customItem}>
          <Text
            onPress={() => {
                auth.signOut();
            }}>
            Log Out
          </Text>
          <Image
            source={{uri: 'https://firebasestorage.googleapis.com/v0/b/order-cash-ee263.appspot.com/o/logout.png?alt=media&token=c0dd9740-4c3d-459a-ba0c-60548d1057e1'}}
            style={styles.iconStyle}
          />
        </View>
      </DrawerContentScrollView>
      <Text
        style={{
          fontSize: 13,
          textAlign: 'center',
          color: 'grey'
        }}>
        www.ordercashapp.com
      </Text>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: 'center',
    width: 40,
    height: 40, 
    alignSelf: 'left',
    marginLeft:10,
    marginTop:10
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default CustomSidebarMenu;