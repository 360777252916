import React, { useState, useContext, useEffect, useRef } from "react";
import {
  View,
  Text,
  Dimensions,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
  Alert,
  Animated,
  Keyboard,
  ScrollView,
} from "react-native";
import AsyncStorage from '@react-native-community/async-storage';
import firebase from "firebase";
import * as Network from "expo-network";
import "firebase/firestore";
import { Context as AuthContext } from "../../context/AuthContext";
import AxiosCall from "../../utilities/Axios"

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;



const FadeInView = (props) => {
  const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

  React.useEffect(() => {
    Animated.timing(
      fadeAnim,
      {
        toValue: 1,
        duration: props.duration || 2000,
        useNativeDriver: true
      }
    ).start();
  }, [fadeAnim])

  return (
    <Animated.View
      // Special animatable View
      style={{
        ...props.style,
        opacity: fadeAnim,         // Bind opacity to animated value
      }}
    >
      {props.children}
    </Animated.View>
  );
}



const Login = ({ navigation }) => {
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
  const { state, checkNetworkConnection } = useContext(AuthContext);
  const [user, setUser] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [indicator, setIndicator] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(new Animated.Value(100));
  const [endHeight, setEndHeight] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [confirmCodeActivity, setConfirmCodeActivity] = useState(false)
  const [verficationMethod, setVerificationMethod] = useState("SMS")
  const [message, setMessage] = useState('')

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    const user = await firebase.auth().currentUser;
    //console.log("user fetched in onRefresh is... ", user);
    setUser(user);
    wait(2000).then(() => setRefreshing(false));
  }, []);



  const signin = async (phoneNumber, mode) => {
    //console.log('phoneNumber is ', phoneNumber)
    setMessage('')
    if (phoneNumber == '' || confirmCodeActivity == true) {
      Alert.alert('Pls enter a phone nmumber')
      setMessage('Pls enter a phone nmumber')
      return
    }
    if (isNaN(phoneNumber)) {
      Alert.alert('Pls enter a phone nmumber')
      setMessage('Pls enter a valid phone nmumber')
      return
    }
    setConfirmCodeActivity(true)
    //navigation.navigate("Verification", { phoneNumber })
    /*  <ActivityIndicator /> */
    try {
      const requestObj = {
        method: "POST",
        path: "signIn",
        data: { phoneNumber: phoneNumber, mode: mode },
      };

      const res = await AxiosCall(requestObj);
      if (res.msg === "OTP_SENT_NEW_USER_CREATED" || res.msg === "OTP_SENT_EXISTING_USER") {
        navigation.navigate("Verification", { phoneNumber })
      }
      else if (res.msg === 'PHONE_NUMBER_TOO_SHORT') {
        Alert.alert('Your Phone Number is too short. Please try again.')
        setMessage('Your Phone Number is too short. Please try again.')
      } else if (res.msg === 'PHONE_NUMBER_TOO_LONG') {
        Alert.alert('Your Phone Number is too long. Please try again.')
        setMessage('Your Phone Number is too long. Please try again.')
      } else {
        setMessage('Something went wrong. Please try again.')
        console.log("sign in message error", res);
      }
      setConfirmCodeActivity(false)
    } catch (err) {
      setConfirmCodeActivity(false)
      console.log("ERROR", err);
    }
  }

  const keyboardWillShow = (event) => {
    setEndHeight(60);
    Animated.parallel([
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: event.endCoordinates.height,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const keyboardWillHide = (event) => {
    setEndHeight(0);
    Animated.parallel([
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: 0,
        useNativeDriver: true,
      }),
    ]).start();
  };
  useEffect(() => {
    const user = firebase.auth().currentUser;
    setUser(user);
    const keyboardWillShowSub = Keyboard.addListener(
      "keyboardWillShow",
      keyboardWillShow
    );
    const keyboardWillHideSub = Keyboard.addListener(
      "keyboardWillHide",
      keyboardWillHide
    );

    return () => {
      console.log("Do some cleanup");
      keyboardWillShowSub.remove();
      keyboardWillHideSub.remove();
    };
  }, []);

  return (
    <View
      style={{
        backgroundColor: "#f7fbfd",
        width: SCREEN_WIDTH,
        height: SCREEN_HEIGHT,
        alignItems: "flex-start",
        padding: 50,
        flexDirection: 'row'
      }}
    >


      <ScrollView
        // keyboardShouldPersistTaps='handled'
        // keyboardShouldPersistTaps='always'
        contentContainerStyle={{
          padding: 10,
          alignItems: "flex-start",
          flex: 1, justifyContent: 'flex-start',
          marginTop: 100
        }}>

        <Text style={{
          fontFamily: "TTCommons-Medium",
          color: "#206bdb",
          fontSize: 24,
          padding: 2,
          margin: 2,
          fontWeight: 'bold',
        }}>
          Phone Number
         </Text>
        <TextInput
          style={{
            color: "#1A315C",
            fontFamily: "TTCommons-Regular",
            fontSize: 13,
            marginVertical: 20,
            minHeight: 50,
            marginTop: 0,
            width: "80%",
            height: 20,
            borderBottomColor: "#206bdb",
            borderBottomWidth: 2,
            textAlign: 'left'
          }}
          placeholder="phone number"
          keyboardType={'number-pad'}
          value={phoneNumber}
          onChangeText={(text) => {
            setPhoneNumber(text)
            console.log('texxxxxt', text);
          }}
        />
        <View>
          <Text style={{fontSize:10, color:'blue'}}>
            {message}
          </Text>
        </View>

        <FadeInView style={{ opacity: fadeAnim, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} duration={500}>
          <TouchableOpacity id="order-button"
            style={{
              backgroundColor: '#D7F0FD', alignSelf: "center", padding: 10, paddingHorizontal: 30,
              justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: 20,
              borderWidth: 2, borderColor: '#206bdb', alignSelf: 'flex-start',
            }}
            onPress={async () => {
              signin(phoneNumber, verficationMethod === 'SMS' ? 'sms' : 'whatsapp')
              Alert.alert("it works!")
            }}
            disabled={confirmCodeActivity}>
            <Text style={{ color: '#206bdb', fontWeight: "bold", fontSize: 12 }}>LOG IN</Text>
          </TouchableOpacity>
          {confirmCodeActivity && <View style={{
            marginTop: 10, alignSelf: 'center', flex: 1, width: '100%',
            height: '100%', marginLeft: 20, alignSelf: 'flex-start',
          }}
          >
            <View>
              <ActivityIndicator
                size="large"
                color="#206bdb"
                style={{
                  opacity: 1,
                  marginVertical: 10
                }}
                animating={true}
              />
            </View>

          </View>}
        </FadeInView>

      </ScrollView>

    </View>
  );
};
export default Login;
