import React, { useEffect, useState, useContext } from "react";
import {
    StyleSheet,
    Text,
    View,
    Dimensions,
    Image,
    Switch,
    TouchableOpacity,
    ScrollView,
    TextInput,
    AsyncStorage,
    ActivityIndicator, Alert
} from "react-native";
import firebase from "firebase";
import Moment from "react-moment";
import "firebase/firestore";
import AxiosCall from "../../utilities/Axios"
import { setStatusBarNetworkActivityIndicatorVisible } from "expo-status-bar";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const OrderDetails = ({ route, navigation }) => {
    // const [clearData, setClearData] = useState(false) 
    const [data, setData] = useState({})
    const [keys, setKeys] = useState([])
    const [secretCode, setSecretCode] = useState('')
    const [indicator, setIndicator] = useState(false)
    const Days = {
        0: "Sun",
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thur",
        5: "Fri",
        6: "Sat",
    };
    const calendarStrings = {
        sameDay: "LT",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "dddd",
        sameElse: "DD-MMM-YY",
    };

    const convertScheduledDate = (time) => {
        if (time == undefined) return
        let a = time.split("/");
        return `${a[1]}/${a[0]}/${a[2]}`;
    };

    const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    function getFullDate(RST) {
        //let rideDate = new Date(RST * 1000);
        var RIDE_DATE = new Date(RST * 1000);
        console.log("RST passed is ", RST, " RIDE_DATE is ", RIDE_DATE)
        var newDATE = RIDE_DATE.toLocaleDateString(undefined, {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
        return Days[RIDE_DATE.getDay()] + " " + newDATE;
    }

    const fetchOrder = async () => {
        try {
            const requestObj = {
                method: "POST",
                path: "getOrders",
                data: {
                    idToken: global.idToken,
                    userID: global.uid,
                    orderID: data.orderID
                },
            };
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {
                setData(res.data)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const verifySecretCode = async (data) => {
        setIndicator(true)
        try {
            const requestObj = {
                method: "POST",
                path: "updateOrder",
                data: {
                    idToken: global.idToken,
                    myUID: global.uid,
                    type: "confirmSecretCode",
                    orderID: data.orderID,
                    code: secretCode
                },
            };
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESS") {
                setIndicator(false)
                await fetchOrder()
            } else if (res.msg === "INCORRECT_CODE") {
                Alert.alert("You entered an incorrect code. Please try again")
            }

            setIndicator(false)
        } catch (err) {
            console.log('Error', err);
        }
    }

    useEffect(() => {
        let keys = Object.keys(data)
        let sorted = keys.sort()
        setKeys(sorted)
        console.log('keys are: ', sorted)
    }, [data])

    useEffect(() => {
        const { itemId } = route.params;
        const { data } = route.params;
        console.log('data passed is ', data, ' id is '.itemId)
        setData(data)
    }, [])

    return (
        <View
            style={{
                backgroundColor: "#f7fbfd",
                height: SCREEN_HEIGHT,
                width: SCREEN_WIDTH,
                alignItems: "center",
                flex: 1, justifyContent: 'center',
            }}
        >

            <ScrollView contentContainerStyle={{
                display: "flex",
                width: "100%",
                paddingTop: 0,
                padding: 20,
                height: "100%",
                position: "relative",
                marginTop: 20
            }}>

                {global.isAgent &&
                    <View>
                        {data.status !== 'Delivered' &&
                            <TextInput
                                style={{
                                    color: "#1A315C",
                                    fontFamily: "TTCommons-Regular",
                                    fontSize: 25,
                                    marginVertical: 20,
                                    marginTop: 5,
                                    minHeight: 50,
                                    width: "80%",
                                    height: 20,
                                    borderBottomColor: "#206bdb",
                                    borderBottomWidth: 2,
                                    textAlign: 'center'
                                }}
                                placeholder="Verify Order Secret"
                                keyboardType={'number-pad'}
                                onChangeText={(text) => {
                                    setSecretCode(text)
                                }}
                                editable={!indicator}
                            />}
                        {/* <TouchableOpacity onPress={() => { updateOrder({ idToken: global.idToken, userID: global.uid, type: "confirmSecretCode", orderID: data.orderID, code: secretCode }) }}>
                            <Text>submit</Text>
                        </TouchableOpacity> */}
                        {data.status === 'Delivered' && <Text style={{
                            color: '#33cb33', fontWeight: 'bold', textAlign: 'left',
                            justifyContent: 'space-between', paddingRight: 0
                        }}>
                            secret:  {data.secretCode} (confirmed)
                        </Text>}
                        {data.status !== 'Delivered' && (
                            <View>
                                {!indicator && <TouchableOpacity onPress={async () => {
                                    await verifySecretCode(data)
                                }}
                                    style={{
                                        backgroundColor: '#D7F0FD', borderColor: '#206bdb', alignItems: 'center', justifyContent: 'center',
                                        padding: 10, borderRadius: 20, borderWidth: 3, marginBottom: 10, width: SCREEN_WIDTH * 0.5
                                    }}>
                                    <Text style={{
                                        color: '#206bdb', fontWeight: 'bold', textAlign: 'center',
                                        justifyContent: 'space-between', paddingRight: 0
                                    }}>
                                        Verify
                                </Text>
                                </TouchableOpacity>}

                                {indicator && <View style={{
                                    backgroundColor: '#D7F0FD', borderColor: '#206bdb', alignItems: 'center', justifyContent: 'center',
                                    borderRadius: 20, borderWidth: 3, marginBottom: 10, width: SCREEN_WIDTH * 0.5
                                }}>
                                    <ActivityIndicator
                                        size="large"
                                        color="#206bdb"
                                        style={{
                                            opacity: 1,
                                            marginVertical: 10
                                        }}
                                        animating={true}
                                    />
                                </View>}
                            </View>
                        )}
                    </View>}

                {data.hasOwnProperty('delivered') && <View style={{ width: SCREEN_WIDTH * 0.8, marginVertical: 5, marginBottom: 200 }}>
                    {keys.map((item, id) => {
                        if (item == 'secretCode') return
                        if (item == 'userID') return
                        if (item == 'orderTotal') return
                        if (item == 'paymentDetails') return
                        if (item == 'paymentVendor') return
                        if (item == 'timeUpated') return
                        if (item == 'orderID') return
                        if (item == 'status') {
                            return (
                                <View key={id} style={{ flexDirection: 'row', width: SCREEN_WIDTH * 0.8, marginVertical: 5 }}>
                                    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {item} :
                                    </Text>
                                    <Text style={{ fontSize: 14, marginLeft: 10, color: data[item] == 'Pending' ? 'blue' : 'green' }}>
                                        {data[item].toString()}
                                    </Text>
                                </View>
                            )
                        }
                        if (item == 'orderAmount') {
                            return (
                                <View key={id} style={{ flexDirection: 'row', width: SCREEN_WIDTH * 0.8, marginVertical: 5 }}>

                                    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {item} :
                                    </Text>
                                    <Text style={{ fontSize: 14, marginLeft: 10, color: '#206bdb', fontWeight: 'bold' }}>
                                        N{formatNumber(data[item])}
                                    </Text>

                                </View>
                            )
                        }
                        if (item == 'orderRequestTime') {
                            return (
                                <View key={id} style={{ flexDirection: 'row', width: SCREEN_WIDTH * 0.8, marginVertical: 5 }}>
                                    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {item} :
                                </Text>
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text style={{ fontSize: 14, marginLeft: 10, color: '#206bdb' }}>
                                            {getFullDate(data[item]._seconds)}
                                        </Text>
                                        <Text style={{ fontSize: 14, marginLeft: 10, color: '#206bdb' }}>
                                            <Moment
                                                fromNow
                                                element={Text}
                                                calendar={calendarStrings}
                                            >
                                                {data[item]._seconds * 1000}
                                            </Moment>
                                        </Text>
                                    </View>

                                </View>
                            )
                        }
                        return (
                            <View key={id} style={{ flexDirection: 'row', width: SCREEN_WIDTH * 0.8, marginVertical: 5 }}>
                                <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                    {item} :
                                </Text>
                                <Text style={{ fontSize: 14, marginLeft: 10 }}>
                                    {data[item].toString()}
                                </Text>
                            </View>
                        )
                    })}

                </View>}
                <View style={{ height: 400 }}>

                </View>
            </ScrollView>

        </View>
    );
};
export default OrderDetails;
const style = {
    Text1: {
        fontFamily: "TTCommons-ExtraBold",
        color: "#1A315C",
        fontSize: 16,
    },
    Text2: {
        fontFamily: "TTCommons-Regular",
        color: "#FCD07F",
        fontSize: 16,
    },
};
