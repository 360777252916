import { AsyncStorage, Alert } from "react-native";
import createDataContext from "./createDataContext";
import AxiosCall from "../utilities/Axios";
import firebase from "firebase";
import "firebase/firestore";
const authReducer = (state, action) => {
  switch (action.type) {
    //signIn REDUCERS
    case "signInRequest_start":
      return { ...state, signInRequestLoading: true };
    case "signInRequest_successful":
      return { ...state, signInRequestSuccess: true, signInRequestResult: action.payload };
    case "signInRequest_error":
      return { ...state, signInRequestError: action.payload, signInRequestLoading: false };
    case "signInRequest_cleanup":
      return { ...state, signInRequestError: null, signInRequestLoading: false, signInRequestSuccess: false };

    //verifyOTP REDUCERS
    case "verifyOTPRequest_start":
      return { ...state, verifyOTPRequestLoading: true };
    case "verifyOTPRequest_successful":
      return { ...state, verifyOTPRequestSuccess: true, verifyOTPRequestResult: action.payload };
    case "verifyOTPRequest_error":
      return { ...state, verifyOTPRequestError: action.payload, verifyOTPRequestLoading: false };
    case "verifyOTPRequest_cleanup":
      return { ...state, verifyOTPRequestSuccess: false, verifyOTPRequestError: null, verifyOTPRequestLoading: false };

    //createOrder REDUCERS
    case "createOrderRequest_start":
      return { ...state, createOrderRequestLoading: true };
    case "createOrderRequest_successful":
      return { ...state, createOrderRequestSuccess: true, createOrderRequestResult: action.payload };
    case "createOrderRequest_error":
      return { ...state, createOrderRequestError: action.payload, createOrderRequestLoading: false };
    case "createOrderRequest_cleanup":
      return { ...state, createOrderRequestSuccess: false, createOrderRequestError: null, createOrderRequestLoading: false };

    //updateOrder REDUCERS
    case "updateOrderRequest_start":
      return { ...state, updateOrderRequestLoading: true };
    case "updateOrderRequest_successful":
      return { ...state, updateOrderRequestSuccess: true, updateOrderRequestResult: action.payload };
    case "updateOrderRequest_error":
      return { ...state, updateOrderRequestError: action.payload, updateOrderRequestLoading: false };
    case "updateOrderRequest_cleanup":
      return { ...state, updateOrderRequestSuccess: false, updateOrderRequestError: null, updateOrderRequestLoading: false };

    //getOrders REDUCERS
    case "getOrdersRequest_start":
      return { ...state, getOrdersRequestLoading: true };
    case "getOrdersRequest_successful":
      return { ...state, getOrdersRequestSuccess: true, getOrdersRequestResult: action.payload };
    case "getOrdersRequest_error":
      return { ...state, getOrdersRequestError: action.payload, getOrdersRequestLoading: false };
    case "getOrdersRequest_cleanup":
      return { ...state, getOrdersRequestSuccess: false, getOrdersRequestError: null, getOrdersRequestLoading: false };

    //createChat REDUCERS
    case "createChatRequest_start":
      return { ...state, createChatRequestLoading: true };
    case "createChatRequest_successful":
      return { ...state, createChatRequestSuccess: true, createChatRequestResult: action.payload };
    case "createChatRequest_error":
      return { ...state, createChatRequestError: action.payload, createChatRequestLoading: false };
    case "createChatRequest_cleanup":
      return { ...state, createChatRequestSuccess: false, createChatRequestError: null, createChatRequestLoading: false };

    //sendChat REDUCERS
    case "sendChatRequest_start":
      return { ...state, sendChatRequestLoading: true };
    case "sendChatRequest_successful":
      return { ...state, sendChatRequestSuccess: true, sendChatRequestResult: action.payload };
    case "sendChatRequest_error":
      return { ...state, sendChatRequestError: action.payload, sendChatRequestLoading: false };
    case "sendChatRequest_cleanup":
      return { ...state, sendChatRequestSuccess: false, sendChatRequestError: null, sendChatRequestLoading: false };

    default:
      return state;
  }
};

//Auth PAGE FUNCTIONS STARTS HERE ==>

const signIn = dispatch => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "signIn",
        data: payload,
      };
      console.log("payloadInput", payload);
      const res = await AxiosCall(requestObj);
      console.log("response from signIn", res);
      await dispatch({
        type: "signInRequest_start",
      });
      if (res.msg === "OTP_SENT_NEW_USER_CREATED" || res.msg === "OTP_SENT_EXISTING_USER") {
        dispatch({
          type: "signInRequest_successful",
          payload: res
        });
      } else {
        dispatch({
          type: "signInRequest_error",
          payload: res,
        });
      }
      dispatch({
        type: "signInRequest_cleanup",
      });
    } catch (err) {
      await dispatch({
        type: "signInRequest_error",
        payload: err,
      });
    }
  };
}

const verifyOTP = dispatch => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "verifyOTP",
        data: payload,
      }
      console.log("payloadInput", payload);
      const res = await AxiosCall(requestObj);
      console.log(res);
      dispatch({
        type: "verifyOTPRequest_start",
      });
      if (res.msg === "SUCCESSFUL_OTP_AUTHENTICATION") {
        await firebase.auth().signInWithCustomToken(res.token);
        console.log("Successfully authenticated with Firebase");
        dispatch({
          type: "verifyOTPRequest_successful",
          payload: res
        });
      }
      if (res.msg === "INCORRECT_OTP_CODE") {
        dispatch({
          type: "verifyOTPRequest_error",
          payload: res.msg
        });
      }
      if (res.msg === "INVALID_OTP_CODE") {
        dispatch({
          type: "verifyOTPRequest_error",
          payload: res.msg
        });
      }
      dispatch({
        type: "verifyOTPRequest_cleanup",
      });
    } catch (err) {
      dispatch({
        type: "verifyOTPRequest_error",
        payload: err,
      });
    }
  }
}

const createOrder = (dispatch) => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "createOrder",
        data: payload,
      };
      console.log("payloadInput", payload);
      const res = await AxiosCall(requestObj);
      console.log("response from create Order", res);
      dispatch({
        type: "createOrderRequest_start",
      });
      if (res.msg === "SUCCESS") {
        dispatch({
          type: "createOrderRequest_successful",
          payload: res
        });
      } else {
        dispatch({
          type: "createOrderRequest_error",
          payload: res,
        });
      }
      dispatch({
        type: "createOrderRequest_cleanup",
      });
    } catch (err) {
      dispatch({
        type: "createOrderRequest_error",
        payload: err,
      });
    }
  };
};

const updateOrder = (dispatch) => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "updateOrder",
        data: payload,
      };
      console.log("payloadInput", payload);
      const res = await AxiosCall(requestObj);
      console.log("response from update Order", res);
      dispatch({
        type: "updateOrderRequest_start",
      });
      if (res.msg === "SUCCESS") {
        dispatch({
          type: "updateOrderRequest_successful",
          payload: res
        });
      } else {
        dispatch({
          type: "updateOrderRequest_error",
          payload: res,
        });
      }
      dispatch({
        type: "updateOrderRequest_cleanup",
      });
    } catch (err) {
      dispatch({
        type: "updateOrderRequest_error",
        payload: err,
      });
    }
  };
};

const getOrders = (dispatch) => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "getOrders",
        data: payload,
      };
      // console.log("payloadInput", payload);
      const res = await AxiosCall(requestObj);
      // console.log("response from get Orders", res);
      dispatch({
        type: "getOrdersRequest_start",
      });
      if (res.msg === "SUCCESS") {
        dispatch({
          type: "getOrdersRequest_successful",
          payload: res
        });
      } else {
        dispatch({
          type: "getOrdersRequest_error",
          payload: res,
        });
      }
      // dispatch({
      //   type: "getOrdersRequest_cleanup",
      // });
    } catch (err) {
      dispatch({
        type: "getOrdersRequest_error",
        payload: err,
      });
    }
  };
};

const createChat = (dispatch) => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "createChat",
        data: payload,
      };
      console.log("payloadInput", payload);
      dispatch({
        type: "createChatRequest_start",
      });
      const res = await AxiosCall(requestObj);
      console.log("response from create chat", res);
      if (res.msg === "SUCCESS") {
        dispatch({
          type: "createChatRequest_successful",
          payload: res
        });
      } else {
        dispatch({
          type: "createChatRequest_error",
          payload: res,
        });
      }
      dispatch({
        type: "createChatRequest_cleanup",
      });
    } catch (err) {
      dispatch({
        type: "createChatRequest_error",
        payload: err,
      });
    }
  };
};

const sendChat = (dispatch) => {
  return async (payload) => {
    try {
      const requestObj = {
        method: "POST",
        path: "sendChat",
        data: payload,
      };
      console.log("payloadInput", payload);
      const res = await AxiosCall(requestObj);
      console.log("response from update Order", res);
      dispatch({
        type: "sendChatRequest_start",
      });
      if (res.msg === "SUCCESS") {
        dispatch({
          type: "sendChatRequest_successful",
          payload: res
        });
      } else {
        dispatch({
          type: "sendChatRequest_error",
          payload: res,
        });
      }
      dispatch({
        type: "sendChatRequest_cleanup",
      });
    } catch (err) {
      dispatch({
        type: "sendChatRequest_error",
        payload: err,
      });
    }
  };
};

export const { Context, Provider } = createDataContext(
  authReducer,
  { signIn, verifyOTP, createOrder, updateOrder, getOrders, createChat, sendChat },
  {
    //signInRequest STATE
    signInRequestLoading: false,
    signInRequestSuccess: false,
    signInRequestResult: null,
    signInRequestError: null,

    //verifyOTPREquest STATE
    verifyOTPRequestLoading: false,
    verifyOTPRequestSuccess: false,
    verifyOTPRequestResult: null,
    verifyOTPRequestError: null,

    //createOrderRequest STATE
    createOrderRequestLoading: false,
    createOrderRequestSuccess: false,
    createOrderRequestResult: null,
    createOrderRequestError: null,

    ///updateOrderRequest STATE
    updateOrderRequestLoading: false,
    updateOrderRequestSucess: false,
    updateOrderRequestResult: null,
    updateOrderRequestError: null,

    ////getOrderRequest STATE
    getOrdersRequestLoading: false,
    getOrdersRequestSucess: false,
    getOrdersRequestResult: null,
    getOrdersRequestError: null,

    ////createChatRequest STATE
    createChatRequestLoading: false,
    createChatRequestSucess: false,
    createChatRequestResult: null,
    createChatRequestError: null,

    ////createChatRequest STATE
    sendChatRequestLoading: false,
    sendChatRequestSucess: false,
    sendChatRequestResult: null,
    sendChatRequestError: null,
  }
);