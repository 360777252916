import Axios from "axios";
import AsyncStorage from '@react-native-community/async-storage';
//import { AsyncStorage, Alert } from "react-native";
import * as Network from "expo-network";

export const baseURL = "https://us-central1-order-cash-ee263.cloudfunctions.net/";

const AxiosCall = async (callObj) => {
  try {
    const networkState = await Network.getNetworkStateAsync();
    if (!networkState.isInternetReachable) {
      Alert.alert("Poor Internet Connection", "Unable to connect to internet");
      return;
    }
  } catch (e) {
    Alert.alert("Poor Internet Connection", "Unable to connect to internet");
  }

  const { path, method, data, contentType } = callObj;

  const token = await AsyncStorage.getItem("token");
  // console.log('token in AxiosCall is ', token)
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": contentType || "application/json",
    //"Content-Type": contentType || "application/x-www-form-urlencoded",
    //Accept: "application/json"
  };

  const url = `${baseURL}${path}`;
  try {
    const response = await Axios({ method, url, data, headers, json: true });

    const result = response && response.data;
    return result;
  } catch (error) {
    throw error;
  }
};

export default AxiosCall;
