/* const AREAS = [ 



Victoria Island */
const VICTORIA_ISLAND_AREAS = [
{label:'1004 First Gate Bus Stop', value:'1004 First Gate Bus Stop'},
{label:'1004 Second Gate Bus Stop', value:'1004 Second Gate Bus Stop'},
{label:'ABC Bus Stop', value:'ABC Bus Stop'},
{label:'Adeola Odeku Bus Stop', value:'Adeola Odeku Bus Stop'},
{label:'Afri Bank Bus Stop', value:'Afri Bank Bus Stop'},
{label:'Banker’s House', value:'Banker’s House'},
{label:'Bonny Camp', value:'Bonny Camp'},
{label:'BRT Bus Station', value:'BRT Bus Station'},
{label:'Car Wash Bus Stop', value:'Car Wash Bus Stop'}, 
{label:'Commerce House Bus Stop', value:'Commerce House Bus Stop'},
{label:'Eko Hotel', value:'Eko Hotel'},
{label:'Engineering Close Bus Station', value:'Engineering Close Bus Station'},
{label:'Federal Palace Bus Stop', value:'Federal Palace Bus Stop'},
{label:'Ican Bus Stop', value:'Ican Bus Stop'},
{label:'Idejo Busstop', value:'Idejo Busstop'},
{label:'Idowu Martin Bus Stop', value:'Idowu Martin Bus Stop'},
{label:'Kofo Abayomi Bus Stop', value:'Kofo Abayomi Bus Stop'},
{label:'IMB Bus Stop', value:'IMB Bus Stop'},
{label:'Lagoon Bus Stop', value:'Lagoon Bus Stop'},
{label:'Landmark Event Center', value:'Landmark Event Center'},
{label:'Law School Bus Stop', value:'Law School Bus Stop'},
{label:'Mega Plaza', value:'Mega Plaza'},
{label:'N.T.A Bus Stop', value:'N.T.A Bus Stop'},
{label:'NNPC Bus Stop', value:'NNPC Bus Stop'},
{label:'Oceanview Bus Stop', value:'Oceanview Bus Stop'},
{label:'Oduduwa Bus Stop', value:'Oduduwa Bus Stop'},
{label:'Olosa Bus Stop', value:'Olosa Bus Stop'},
{label:'Oniru Beach', value:'Oniru Beach'},
{label:'Oniru Bus Stop', value:'Oniru Bus Stop'},
{label:'Saka Tinubu', value:'Saka Tinubu'},
{label:'Sandfill Bus Stop', value:'Sandfill Bus Stop'},
{label:'Silverbird Galleria', value:'Silverbird Galleria'},
{label:'Taimiyu Savage Junction', value:'Taimiyu Savage Junction'},
{label:'The Palms Shopping Mall', value:'The Palms Shopping Mall'},
{label:'Union Bank Bus Stop', value:'Union Bank Bus Stop'},
]

const LEKKI_AREAS = [
    { label: 'Agungi', value: 'Agungi' },
    { label: 'Chevron', value: 'Chevron' },
    { label: 'Chisco', value: 'Chisco' },
    { label: 'Eleganza', value: 'Eleganza' },
    { label: 'Elf', value: 'Elf' },
    { label: 'Igbo Efon', value: 'Igbo Efon' },
    { label: 'Ikate', value: 'Ikate' },
    { label: 'Ikota', value: 'Ikota' },
    { label: 'Jakande', value: 'Jakande' },
    { label: 'Lekki', value: 'Lekki' },
    { label: 'Lekki County', value: 'Lekki County' },
    { label: 'Lekki Phase 1', value: 'Lekki Phase 1' },
    { label: 'Marwa', value: 'Marwa' },
    { label: 'Mega Chicken, Ikota', value: 'Mega Chicken, Ikota' },
    { label: 'New Road', value: 'New Road' },
    { label: 'VGC', value: 'VGC' },
]


const IKOYI_AREAS = [
    { label: 'Alexander', value: 'Alexander' },
    { label: 'Alfred Rewane Rd', value: 'Alfred Rewane Rd' },
    { label: 'Awolowo Rd', value: 'Awolowo Rd' },
    { label: 'Bourdillon Rd', value: 'Bourdillon Rd' },
    { label: 'Cooper Rd', value: 'Cooper Rd' },
    { label: 'Dolphin Estate', value: 'Dolphin Estate' },
    { label: 'Gerard', value: 'Gerard' },
    { label: 'Glover Rd', value: 'Glover Rd' },
    { label: 'Ikoyi', value: 'Ikoyi' },
    { label: 'Osbourne Rd', value: 'Osbourne Rd' },
]

const AREAS = [
    { label: 'Ikoyi', value: 'Ikoyi' },
    { label: 'Lekki', value: 'Lekki' },
    { label: 'Victoria Island', value: 'Victoria Island' },
    { label: 'My area is not here', value: 'n/a' },
]
export { AREAS, LEKKI_AREAS, IKOYI_AREAS, VICTORIA_ISLAND_AREAS };

//estimateRidePrice("Lekki", "Ajah")
