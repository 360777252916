import React, { useState, useEffect, useContext, } from "react";
import { Text, View, useWindowDimensions } from "react-native";
import AsyncStorage from '@react-native-community/async-storage';
import { Context as AuthContext } from "../context/AuthContext";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
const auth = firebase.auth();
import CustomSidebarMenu from './CustomSideBarMenu'
import Login from "../Screens/Auth/Login";
import Verification from "../Screens/Auth/Verification" 
import Home from "../Screens/Main/HomeScreen";
import Settings from "../Screens/Main/SettingsScreen";
import OrderDetails from "../Screens/Main/OrderDetails";
import Orders from "../Screens/Main/Orders";
import ChatScreen from "../Screens/Main/ChatScreen"
import ChattingScreen from "../Screens/Main/ChattingScreen";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator(); 
 
function OrderStackScreen() {

  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;

  return (
    <Drawer.Navigator initialRouteName="Orders"
      openByDefault={false}
      drawerType={"front"}
      drawerStyle={isLargeScreen ? null : { width: '50%' }}
      overlayColor="transparent"
      drawerContentOptions={{
        activeTintColor: '#e91e63',
        itemStyle: { marginVertical: 5 },
      }}
      drawerContent={(props) => <CustomSidebarMenu {...props} />}>
      <Drawer.Screen name="Orders" component={Orders} options={{ headerShown: true, drawerLabel: 'Orders' }} />
      <Drawer.Screen name="ChatScreen" component={ChatScreen} options={{ headerShown: true, drawerLabel: 'Customers' }} />
      <Drawer.Screen name="ChattingScreen" component={ChattingScreen} options={{ headerShown: true, drawerLabel: 'Chats' }} />
      {/* <Drawer.Screen label="Logout" onPress={() => navigation.navigate("Login")} /> */}
    </Drawer.Navigator>
  )
}


const Tab = createBottomTabNavigator();
const Navigation = () => {
  const { state } = useContext(AuthContext);
  const [userId, setUserId] = useState(null);
  const [OrdersId, setOrdersId] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false)
  const [user] = useAuthState(auth);

  const getSignInData = async () => {
    try {
      const data = await AsyncStorage.getItem("signInData");
      const parseData = JSON.parse(data);
      if (parseData !== null) {
        console.log('user', parseData)
        setUserId(parseData.uid)
        setLoggedIn(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("error!", error)
    }
  };

  useEffect(() => {
    if (userId != null) {
      //setLoggedIn(true);
    } else {
      //setLoggedIn(false);
    }
    getSignInData();
  }, [userId, state.signedIn]);

  return (
    <NavigationContainer>
      {user ? <OrderStackScreen />
        : <Stack.Navigator
          screenOptions={{
            headerStyle: {
              backgroundColor: "#1A315C",
            },
            headerTintColor: "#fff",
            headerTitleStyle: {
              fontWeight: "bold",
            },
          }}>
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Verification"
            component={Verification}
            options={{ headerShown: false }}
          />
        </Stack.Navigator>
      }
    </NavigationContainer>
  );
};

export default Navigation;
