import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  StatusBar,
  Image,
} from "react-native";
import firebase from "./firebase/firebaseConfig";
import { enableScreens } from "react-native-screens";
import * as SplashScreen from "expo-splash-screen";
import { Asset } from "expo-asset";

import { createStackNavigator } from "@react-navigation/stack";
import * as Font from "expo-font";
import Navigation from "./src/navigation/Navigation";

import { Provider as AuthProvider } from "./src/context/AuthContext";
//needed for screen & navigation optimization across the two platforms see here https://www.npmjs.com/package/react-native-screens

enableScreens();

//const Stack = createStackNavigator();

export default function App() {
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const _cacheSplashResourcesAsync = async () => {
    const gif = require("./assets/ordercash.png");
    return Asset.fromModule(gif).downloadAsync();
  };

  const _cacheResourcesAsync = async () => {
    SplashScreen.hideAsync();
    // await Promise.all(cacheImages);
    setIsReady(true);
  };

  useEffect(() => {
    SplashScreen.hideAsync();
    async function loadAssets() {
      await Promise.all([
        Font.loadAsync({
          "TTCommons-ExtraBold": require("./assets/Fonts/TTCommons-ExtraBold.ttf"),
          "TTCommons-Medium": require("./assets/Fonts/TTCommons-Medium.ttf"),
          "TTCommons-Regular": require("./assets/Fonts/TTCommons-Regular.ttf"),
          "TTCommons-Thin": require("./assets/Fonts/TTCommons-Thin.ttf"),
        }),
      ]);
      setAssetsLoaded(true);
    }

    loadAssets();
  }, []);

  if (!isReady) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Image
          source={require("./assets/order-cash-mobile.png")}
          onLoad={_cacheResourcesAsync}
        />
      </View>
    );
  }

  if (assetsLoaded) {
    return (
      <AuthProvider>
        <Navigation />
      </AuthProvider>
    );
  } else {
    return (
      <View style={styles.container}>
        <ActivityIndicator />
        <StatusBar barStyle="default" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#206bdb",
    alignItems: "center",
    justifyContent: "center",
  },
});
