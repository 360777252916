import firebase from "firebase";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDYuLhUvMcfbkOr6Cyi_X-TE4Xpx7IOqbE",
  authDomain: "order-cash-ee263.firebaseapp.com",
  databaseURL: "https://order-cash-ee263.firebaseio.com",
  projectId: "order-cash-ee263",
  storageBucket: "order-cash-ee263.appspot.com",
  messagingSenderId: "616414109862",
  appId: "1:616414109862:web:b5a8c1a0d387b871bb5467",
  measurementId: "G-99WS95EV9X"
};

//firebase.initializeApp(config);
export default firebase.initializeApp(config);