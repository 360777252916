import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  Image,
  Text,
  View,
  Dimensions,
  TextInput,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Animated,
} from "react-native";
import firebase from "firebase";
import AsyncStorage from '@react-native-community/async-storage';
import { Context as AuthContext } from "../../context/AuthContext";
import { AntDesign } from "@expo/vector-icons";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const ChatScreen = ({ navigation }) => {
  //const { state, checkNetworkConnection } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const [opponentID, setOpponentID] = useState("");
  const [opponentName, setOpponentName] = useState("");
  const [chats, setChats] = useState([]);
  const [indicator, setIndicator] = useState(false);

  const getChatsData = async () => {
    try {
      const chats2 = await AsyncStorage.getItem("Chats");

      const parseData2 = JSON.parse(chats2);
      // console.log('parsed RideRequests data in localstorage is ', parseData2)
      if (parseData2 !== null) {
        setChats(parseData2 || []);
      }
    } catch (error) {
      console.log("Error ocurreed", error);
    }
  };

  const getAllChat = async () => {
    // console.log('globallll', global.userID);
    //await checkNetworkConnection()
    if (!state.connected) {
      return
    }
    await firebase
      .firestore()
      .collection("Chats")
      .get()
      .then((query) => {
        let chatArray = [];
        query.forEach(async (doc) => {
          const chatDoc = doc.data();
          chatDoc["chatID"] = doc.id;
          // console.log('chatdoooooooc', chatDoc, global.userID);

          if (
            global.userID === chatDoc.userID1 ||
            global.userID === chatDoc.userID2
          ) {
            if (global.userID !== chatDoc.userID2) {
              const opponentRef = await firebase
                .firestore()
                .collection("Users")
                .doc(chatDoc.userID2)
                .get();
              if (opponentRef.exists) {
                const opponentDoc = opponentRef.data();
                chatDoc["opponentName"] =
                  opponentDoc.firstName + " " + opponentDoc.lastName;
              }
            } else {
              const opponentRef = await firebase
                .firestore()
                .collection("Users")
                .doc(chatDoc.userID1)
                .get();
              if (opponentRef.exists) {
                const opponentDoc = opponentRef.data();
                chatDoc["opponentName"] =
                  opponentDoc.firstName + " " + opponentDoc.lastName;
              }
            }

            chatArray.push(chatDoc);
          }
          AsyncStorage.setItem("Chats", JSON.stringify(chatArray || []));
        });
        setChats(chatArray)
        console.log('chatsssss', chats);
      })
      .catch((err) => {
        console.log("An error ocurred in ChatScreen", err);
      });
  };

  // console.log('globallll', global.userID);
  useEffect(() => {
    //checkNetworkConnection();
    // console.log(" states ", state.connected);
    // if (!state.connected) {
    //   console.log("cannot access firebase right now turn wifi on");
    //   return;
    // } else {
    getChatsData();
    getAllChat();
    // }

  }, [global.userID]);
  // console.log('name', opponentName);
  return (
    <View
      style={{
        backgroundColor: "#f7fbfd",
        height: SCREEN_HEIGHT,
        width: SCREEN_WIDTH,
        alignItems: "center",
      }}
    >
      <View>
        <ScrollView
          contentContainerStyle={{
            alignItems: "center",
            width: SCREEN_WIDTH,
            padding: 20,
            paddingTop: 0,
          }}
        >
          <View
            style={{
              alignItems: "center",
              height: "100%",
              display: "flex",
              flex: 1,
              marginBottom: 200,
            }}
          >
            {chats.length == 0 && <View style={{ justifyContent: 'center', marginTop: 150, alignItems: 'center' }}>
              <Text style={{ color: '#206bdb' }}>Your chats will appear here.</Text>
              <Text style={{ color: '#206bdb' }}>You currently have no messages.</Text>
            </View>}


            {chats.length > 0 &&
              chats.map((item, id) => {
                // console.log('chatgrouuup', item);
                return (
                  <View style={{ margin: 10 }} key={id}>
                    <TouchableOpacity
                      id="one-chat-block"
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                        height: 80,
                        marginTop: 0,
                      }}
                      onPress={() => {
                        navigation.navigate("ChattingScreen", {
                          chatID: item.chatID,
                          userID1: item.userID1,
                          userID2: item.userID2,
                        });
                      }}
                    >
                      {/* <View style={{ width: "16%" }}>
                        <Image
                          source={require("../../../assets/PROFILE4.png")}
                          style={{ maxWidth: 50, maxHeight: 50 }}
                          resizeMode="contain"
                        />
                      </View> */}

                      <View style={{ width: "60%", paddingRight: 10 }}>
                        <Text
                          style={{
                            fontFamily: "TTCommons-Regular",
                            color: "#FCD07F",
                            fontSize: 15,
                            textAlign: "left",
                          }}
                          numberOfLines={1}
                        >
                          {item.opponentName}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "TTCommons-Regular",
                            color: "#6489CE",
                            fontSize: 15,
                            textAlign: "left",
                            marginTop: 5,
                          }}
                          numberOfLines={1}
                        >
                          {item.chatMessages[item.chatMessages.length - 1].text}
                        </Text>
                      </View>
                      <View style={{ justifyContent: "flex-end" }}>
                        <Text
                          style={{
                            fontFamily: "TTCommons-Regular",
                            color: "#6489CE",
                            fontSize: 13,
                            textAlign: "right",
                          }}
                          numberOfLines={1}
                        >
                          12:00PM
                        </Text>
                        <View
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#FCD07F",
                            minWidth: 20,
                            alignSelf: "flex-start",
                            marginTop: 5,
                            padding: 2,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "TTCommons-Regular",
                              color: "#6489CE",
                              fontSize: 13,
                              textAlign: "center",
                            }}
                          >
                            {item.chatMessages.length}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                );
              })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
export default ChatScreen;
