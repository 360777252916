import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    Text,
    View,
    Dimensions,
    Image,
    Switch,
    TouchableOpacity,
    ScrollView, 
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import AsyncStorage from '@react-native-community/async-storage';
import { AntDesign } from "@expo/vector-icons";
// import HomeComponent from "../Components/HomeComponent";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const SettingsScreen = ({ navigation }) => {
    // const [clearData, setClearData] = useState(false)
    const [userID, setUserID] = useState("");
    const [userEmail, setUserEmail] = useState("")
    const [isDriver, setIsDriver] = useState("");
    const [name, setName] = useState("");

    // const toggleSwitch = () => {
    //     setClearData((previousState) => !previousState);
    //     if (clearData === true) {
    //         AsyncStorage.getAllKeys((err, keys) => {
    //             AsyncStorage.multiRemove(keys, (err) => {
    //                 console.log("Removing keys from asyncstorage", err, keys);
    //             });
    //         });
    //     }
    // }
    const clearData = async () => {
        try {
            await firebase
                .auth()
                .signOut()
                .then(function () {
                    console.log("Signed out Successfully");
                    AsyncStorage.getAllKeys((err, keys) => {
                        AsyncStorage.multiRemove(keys, (err) => {
                            console.log("Removing keys from asyncstorage", err, keys);
                        });
                    });
                })
                .catch(function (error) {
                    // An error happened.
                    console.log("An  error ocurred");
                });
            await new Promise((resolve) => {
                setTimeout(resolve, 1000);
            });
            // await navigation.navigate("HomeScreen");
        } catch (err) {

        }
    }
    return (
        <View
            style={{
                backgroundColor: "#f7fbfd",
                height: SCREEN_HEIGHT,
                width: SCREEN_WIDTH,
                alignItems: "center",
                flex: 1, justifyContent: 'center',
            }}
        >
            {/* <View style={{marginTop:20}}><Text style={{color:'#EBBE67', fontSize: 16}}>CoRyde</Text></View> */}
            <ScrollView contentContainerStyle={{
                padding: 20,
                alignItems: "center",
                flex: 1, justifyContent: 'center'
            }}>
                <View style={{ height: 100 }}>

                </View>
                {/* <Switch
                    trackColor={{ false: "#6489CE", true: "#FCD07F" }}
                    thumbColor={clearData ? "#6C63FF" : "#FEFEFE"}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitch}
                    value={clearData}
                /> */}
                <TouchableOpacity onPress={() => {
                    clearData()
                }}
                    style={{
                        backgroundColor: '#D7F0FD', borderColor: '#206bdb', alignItems: 'center', justifyContent: 'center',
                        marginTop: 10, padding: 10, borderRadius: 20, borderWidth: 3,
                    }}>
                    <Text style={{  color: '#206bdb', fontWeight: 'bold', textAlign:'center',
                        width: SCREEN_WIDTH * 0.6, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 0
                    }}>
                        Clear Data
                        </Text>
                </TouchableOpacity>
                {/* <TouchableOpacity onPress={() => { clearData() }}>
                    <Text>clearData</Text>
                </TouchableOpacity> */}
            </ScrollView>
            <View style={{ height: 100 }}>

            </View>
        </View>
    );
};
export default SettingsScreen;
const style = {
    Text1: {
        fontFamily: "TTCommons-ExtraBold",
        color: "#1A315C",
        fontSize: 16,
    },
    Text2: {
        fontFamily: "TTCommons-Regular",
        color: "#FCD07F",
        fontSize: 16,
    },
};
