import React, { useState, useContext, useEffect, useRef } from "react";
import {
    View,
    Text,
    Dimensions,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    RefreshControl,
    Alert,
    Animated,
    Keyboard,
    ScrollView,
} from "react-native";
import AsyncStorage from '@react-native-community/async-storage';
import firebase from "firebase";
import * as Network from "expo-network";
import "firebase/firestore";
import { Context as AuthContext } from "../../context/AuthContext";
import AxiosCall from "../../utilities/Axios"

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;



const FadeInView = (props) => {
    const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

    React.useEffect(() => {
        Animated.timing(
            fadeAnim,
            {
                toValue: 1,
                duration: props.duration || 2000,
                useNativeDriver: true
            }
        ).start();
    }, [fadeAnim])

    return (
        <Animated.View
            // Special animatable View
            style={{
                ...props.style,
                opacity: fadeAnim,         // Bind opacity to animated value
            }}
        >
            {props.children}
        </Animated.View>
    );
}



const Login = ({ navigation, route }) => {
    const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
    const { state, checkNetworkConnection } = useContext(AuthContext);
    const [user, setUser] = useState("");
    const [otpNumber, setOtpNumber] = useState();
    const [phoneNumber, setPhoneNumber] = useState()
    const [indicator, setIndicator] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(new Animated.Value(100));
    const [endHeight, setEndHeight] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [confirmCodeActivity, setConfirmCodeActivity] = useState(false)
    const [verficationMethod, setVerificationMethod] = useState("SMS")

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        const user = await firebase.auth().currentUser;
        console.log("user fetched in onRefresh is... ", user);
        setUser(user);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    useEffect(() => {
        if (route.params !== undefined) {
            setPhoneNumber(route.params.hasOwnProperty('phoneNumber') ? route.params.phoneNumber : "")
        }
    }, [])
    const confirmCode = async () => {
        setConfirmCodeActivity(true)
        
        try {
            const requestObj = {
                method: "POST",
                path: "verifyOTP",
                data: { phoneNumber: phoneNumber, otpCode: otpNumber },
            }
            const res = await AxiosCall(requestObj);
            if (res.msg === "SUCCESSFUL_OTP_AUTHENTICATION") {
                await firebase.auth().signInWithCustomToken(res.token);
                const currentUser = firebase.auth().currentUser
                console.log('current useeer', currentUser);
                if (currentUser) {
                    const uid = currentUser.uid
                    global.uid = uid
                    AsyncStorage.setItem("signInData", JSON.stringify(currentUser))
                }
                setConfirmCodeActivity(false)
            } else if (res.msg === "INCORRECT_OTP_CODE") {
                Alert.alert("You entered an incorrect OTP_CODE, Try again")
                console.log(res.msg);
                setConfirmCodeActivity(false)
            }
            else if (res.msg === "INVALID_OTP_CODE") {
                Alert.alert("You entered an invalid OTP_CODE, Crosscheck and Try again")
                console.log(res.msg);
                setConfirmCodeActivity(false)
            } else {
                console.log("An Error ocurred in verifyOTP", res);
                setConfirmCodeActivity(false)
            }
        } catch (err) {
            await setConfirmCodeActivity(false)
            console.log("ERROR", err);
            Alert.alert('Sorry! something went wrong, please try again!')
        }
    }

    const keyboardWillShow = (event) => {
        setEndHeight(60);
        Animated.parallel([
            Animated.timing(keyboardHeight, {
                duration: event.duration,
                toValue: event.endCoordinates.height,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const keyboardWillHide = (event) => {
        setEndHeight(0);
        Animated.parallel([
            Animated.timing(keyboardHeight, {
                duration: event.duration,
                toValue: 0,
                useNativeDriver: true,
            }),
        ]).start();
    };
    useEffect(() => {
        const user = firebase.auth().currentUser;
        setUser(user);
        const keyboardWillShowSub = Keyboard.addListener(
            "keyboardWillShow",
            keyboardWillShow
        );
        const keyboardWillHideSub = Keyboard.addListener(
            "keyboardWillHide",
            keyboardWillHide
        );

        return () => {
            console.log("Do some cleanup");
            keyboardWillShowSub.remove();
            keyboardWillHideSub.remove();
        };
    }, []);

    return (
        <View
            style={{
                backgroundColor: "#f7fbfd",
                width: SCREEN_WIDTH,
                height: SCREEN_HEIGHT,
                alignItems: "flex-start",
                padding: 50,
                flexDirection: 'row'
            }}
        >


            <ScrollView
                // keyboardShouldPersistTaps='handled'
                // keyboardShouldPersistTaps='always'
                contentContainerStyle={{
                    padding: 10,
                    alignItems: "flex-start",
                    flex: 1, justifyContent: 'flex-start',
                    marginTop: 100
                }}>

                <Text style={{
                    fontFamily: "TTCommons-Medium",
                    color: "#206bdb",
                    fontSize: 24,
                    padding: 2,
                    margin: 2,
                    fontWeight: 'bold',
                }}>
                    OTP
         </Text>
                <TextInput
                    style={{
                        color: "#1A315C",
                        fontFamily: "TTCommons-Regular",
                        fontSize: 13,
                        marginVertical: 20,
                        minHeight: 50,
                        marginTop: 0,
                        width: "80%",
                        height: 20,
                        borderBottomColor: "#206bdb",
                        borderBottomWidth: 2,
                        textAlign: 'left'
                    }}
                    placeholder="otp"
                    keyboardType={'number-pad'}
                    onChangeText={(text) => {
                        setOtpNumber(text)
                        console.log('texxxxxt', text);
                    }}
                />

                <FadeInView style={{ opacity: fadeAnim, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} duration={500}>
                    <TouchableOpacity id="order-button"
                        style={{
                            backgroundColor: '#D7F0FD', alignSelf: "center", padding: 10, paddingHorizontal: 30,
                            justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: 20,
                            borderWidth: 2, borderColor: '#206bdb', alignSelf: 'flex-start',
                        }}
                        onPress={async () => {
                            await confirmCode()
                            Alert.alert("it works!")
                        }}
                        disabled={confirmCodeActivity}>
                        <Text style={{ color: '#206bdb', fontWeight: "bold", fontSize: 12 }}>Verify</Text>
                    </TouchableOpacity>
                    {confirmCodeActivity && <View style={{
                        marginTop: 20, alignSelf: 'center', flex: 1, width: '100%',
                        height: '100%', marginLeft: 20, alignSelf: 'flex-start',
                    }}
                    >
                        <View>
                            <ActivityIndicator
                                size="large"
                                color="#206bdb"
                                style={{
                                    opacity: 1,
                                }}
                                animating={true}
                            />
                        </View>

                    </View>}
                </FadeInView>

            </ScrollView>

        </View>
    );
};
export default Login;
